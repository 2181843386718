<template>
    <v-row class="payment-container">

        <div class="okolo">
            <v-row justify="center" class="mt-0 text-center">
                <v-col justify="center" cols="12" class="mt-5">
                    <v-alert style="width: 60%;align-items: center;border-radius: 12px"
                            v-if="payment.status"
                            outlined
                            :type="payment.color"
                            text
                            class="font-weight-medium mb-1 mx-auto pb-2 text-h6 text-center"
                    >
                        <span v-if="payment1.status">
                          {{translate(payment1.text)}}
                        </span>
                        <span v-else>
                            <span class="font-weight-bold text-uppercase">{{translate(payment.text)}}</span> <br>
                            <span v-if="show_user_firm" class="red--text font-weight-bold text-h4" style="font-weight:700;font-size: 19px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">
<!--                              <span  style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> <span style="color:#E03232">{{translate(user.name)}} {{translate(user.lastname)}}</span> <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ user.companies[0].name }}</span>-->
                            </span>
                        </span>
                    </v-alert>

                    <v-row v-if="timer.show">
                        <v-col cols="12">
                            <h1 style="font-size: 20px" class="text-center">{{ $t('next_wait') }} {{timer.status + ' s'}}</h1>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
          <h1 v-if="payment.status" class="red--text font-weight-bold text-h4 text-center mt-4" style="font-weight:700;font-size: 29px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">
            <span style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> {{translate(user.name)}} {{translate(user.lastname)}} <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ translate(user.companies[0].name) }}</span>
          </h1>
          <v-row v-if="user && user.orders.length && payment.color === 'error'">
            <template v-if="!user.user_wrong_location" v-for="(orders, i) in user.orders">
              <v-col cols="8" class="mx-auto mt-5" v-if="orders.transaction && orders.transaction.length">
                <span style="display: block;text-align: left;padding-left: 20px;font-size: 26px;color: rgb(251, 109, 59)" v-for="food in orders.transaction" :title="'ORDER: ' + orders.id"> - {{translate(food.food.name)}} {{food.quantity}} kom</span>
              </v-col>
              <v-col cols="12" v-else>
                <span style="font-size: 26px;color: rgb(251, 109, 59);text-align: center;display: block;margin-top: 25px">- {{translate(orders.meals.name)}} {{orders.quantity}} kom</span>
              </v-col>
            </template>
            <template v-if="user.user_wrong_location" v-for="(orders, i) in user.orders">
              <v-col cols="12" class="text-center mt-5">
                - {{translate(orders.meals.name)}} {{orders.quantity}} kom
              </v-col>
            </template>
          </v-row>
          <div justify="center" class="mt-0 pt-0" v-if="menus.length>0">
            <v-row justify="center" v-if="user" class="pb-1 mt-3">
<!--              <h1 class="red&#45;&#45;text font-weight-bold text-h4" style="font-weight:700;font-size: 29px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">-->
<!--                <span style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> {{translate(user.name)}} {{translate(user.lastname)}} <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ user.companies[0].name }}</span>-->
<!--              </h1>-->
            </v-row>
            <div class="" style="width: 60%;margin: 20px auto;text-align: center">

              <template v-if="menus[0].shift == '1-smena'">
                <h1 v-if="! payment.status" class="red--text font-weight-bold text-h4 text-center mt-4 mb-3" style="font-weight:700;font-size: 29px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">
                  <span style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> {{translate(user.name)}} {{translate(user.lastname)}} <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ translate(user.companies[0].name) }}</span>
                </h1>
                <div class="div-okolo">
                  <span class="shift-title-p shift1">{{ translate('Наручено за')}}: {{ translate(shifts[menus[0].shift]) }}</span>
                  <span v-if="shiftOneTime && shiftOneTime != 'undefined:undefined - undefined:undefined'">
                     <v-icon style="margin-top: -4px" :title="$t('edit')" small> mdi-clock </v-icon>
                     {{ shiftOneTime }}
                  </span>
                </div>
                <v-alert style="width: 100%;align-items: center;border-radius: 12px"
                           v-if="payment1.status && moze"
                           outlined
                           :type="payment1.color"
                           text
                           class="font-weight-medium mb-1 mx-auto pb-2 text-h6 text-center"
                  >
                      <span v-if="payment1.status">{{translate(payment1.text)}}</span>
                      <span v-else>
                        {{translate(payment.text)}}
                    </span>
                </v-alert>
              </template>
              <template v-else-if="menus[0].shift == '2-smena'">
                <h1 v-if="! payment.status" class="red--text font-weight-bold text-h4 text-center mt-4 mb-3" style="font-weight:700;font-size: 29px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">
                  <span style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> {{translate(user.name)}} {{translate(user.lastname)}} <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ translate(user.companies[0].name) }}</span>
                </h1>
                <div class="div-okolo">
                  <span class="shift-title-p shift2">{{ translate('Наручено за')}}: {{translate(shifts[menus[0].shift]) }}</span>
                  <span v-if="shiftTwoTime && shiftTwoTime != 'undefined:undefined - undefined:undefined'">
                     <v-icon style="margin-top: -4px" :title="$t('edit')" small> mdi-clock </v-icon>
                    {{ shiftTwoTime }}
                  </span>
                </div>
                <v-alert style="width: 100%;align-items: center;border-radius: 12px"
                           v-if="payment1.status && moze"
                           outlined
                           :type="payment1.color"
                           text
                           class="font-weight-medium mb-1 mx-auto pb-2 text-h6 text-center"
                  >
                      <span v-if="payment1.status">{{translate(payment1.text)}}</span>
                      <span v-else>
                        {{translate(payment.text)}}
                    </span>
                  </v-alert>
              </template>
              <template v-else-if="menus[0].shift == '3-smena'">
                <h1 v-if="! payment.status" class="red--text font-weight-bold text-h4 text-center mt-4 mb-3" style="font-weight:700;font-size: 29px !important;font-family:'Poppins', Helvetica, sans-serif; !important;color: rgb(251, 109, 59) !important;">
                  <span style="color: rgba(0,0,0,0.39)">{{user.id}}.</span> {{translate(user.name)}} {{translate(user.lastname)}} <span style="color: rgba(0,0,0,0.39);font-weight: 500">{{ translate(user.companies[0].name) }}</span>
                </h1>
                <div class="div-okolo">
                  <span class="shift-title-p shift3">{{ translate('Наручено за')}}: {{ translate(shifts[menus[0].shift]) }}</span>
                  <span v-if="shiftThreeTime && shiftThreeTime != 'undefined:undefined - undefined:undefined'">
                     <v-icon style="margin-top: -4px" :title="$t('edit')" small> mdi-clock </v-icon>
                    {{ shiftThreeTime }}
                  </span>
                </div>
                <v-alert style="width: 100%;align-items: center;border-radius: 12px"
                         v-if="payment1.status && moze"
                         outlined
                         :type="payment1.color"
                         text
                         class="font-weight-medium mb-1 mx-auto pb-2 text-h6 text-center"
                >
                    <span v-if="payment1.status">{{translate(payment1.text)}}</span>
                    <span v-else>
                        {{translate(payment.text)}}
                    </span>
                </v-alert>
              </template>
            </div>
            <!--  <h2  class="pb-3 red--text text--darken-2 font-weight-bold text-h4">{{shifts[menus[0].shift]}}</h2> -->
          </div>
            <v-row style="min-height: 580px;overflow-y: scroll; overflow-x: hidden">
              <v-col cols="12">
                <v-row v-if="selected" class="justify-center">
                  <template v-for="menu in menus" class="justify-center">
                    <v-row class="justify-center my-1">
                    <div style="background: #fff;border-bottom: 1px solid #dadada;padding-bottom: 10px" v-for="m in mealsForCurrentDay" class="">
                      <span v-if="m.id == menu.meal_id">
                         <v-img style="border-radius: 12px !important;"
                             class="flex justify-center rounded"
                             height="150"
                             width="150"
                             v-if="m.image"
                             :src="
                                conf.apiUrlBase +
                                  '/gallery/' +
                                  $store.state.company.restaurant.id +
                                  '/medium/' +
                                  m.image.name
                            "
                         ></v-img>
                         <v-img class="flex justify-center rounded"
                                width="150" v-else height="150" :src="'https://cdn.vuetifyjs.com/images/cards/cooking.png'"></v-img>
                      </span>
                    </div>
                    <v-card outlined style="border-bottom: 1px solid #dadada;border-radius: 0" width="42vw" class="pa-3 mt-0 pt-0 red--text text--darken-2 justify-center" :class="+menu.quantity_payed == 0 ? 'white--text' : ''" :color="menu.quantity_payed == 0 ? 'rgb(94,33,31,0.5)' : 'rgb(255,255,255, 1)'">
                        <p style="color: #101010;line-height: 25px;font-size: 21px;padding: 0 10px;font-weight: 900 !important;" class="pb-0 mb-0">
                          <b>{{menu.meals && menu.meals.name ? translate(menu.meals.name) : ''}}</b>
                        </p>
<!--                              <div style="color: #101010" class="pb-2 font-weight-medium text-h6">{{translate("Садржи: ")}} </div>-->
                      <v-row>
                        <v-col :cols="menu.meals.length && menu.meals[0].order_food_id ? 12 : 10" v-if="menu.meals && menu.meals.foods.length">
                          <template v-for="(food, i) in menu.meals.foods">
                            <v-col :style="menu.meals[i] && menu.meals[i].order_food_id ? 'display:flex;align-items:center;border:1px solid #e6e6e6;padding:5px;border-radius:7px;margin-bottom:10px' : ''" style="color: rgba(0,0,0,0.4);font-size: 18px !important;font-family:'Poppins', Helvetica, sans-serif; !important;font-weight: 700 !important;" class="py-2 px-2 font-weight-medium">
                              <span v-if="menu.meals[i] && menu.meals[i].order_food_id" style="display: flex;align-items: center">
                                 <v-img style="border-radius: 4px !important;"
                                        class="flex justify-center rounded"
                                        height="70"
                                        width="70"
                                        accept="jpg/png"
                                        v-if="food.image"
                                        :src="
                                   conf.apiUrlBase +
                                  '/gallery/' +
                                  $store.state.company.restaurant.id +
                                  '/medium/' +
                                  food.image.name
                                  "
                                 ></v-img>
                                <span style="padding-left: 10px;color: rgb(251, 109, 59) !important;font-size: 26px">{{menu.meals[i].quantity}}x </span>
                              </span>
                              <span v-else>{{i+1}}. </span>
                              <span style="padding-left: 5px;font-size: 20px">{{translate(food.name)}}</span>
                            </v-col>
                          </template>
                        </v-col>
                        <v-col cols="2">
                          <template v-if="(menu.quantity>1||menus.length>1)">
                            <v-row class="flex-wrap mt-3">
                              <div class="w-25">
                                <v-text-field style="font-size: 20px"
                                    :label="$t('ordered_1')"
                                    hide-details
                                    dense
                                    outlined
                                    class="red--text text--darken-2 mr-2"
                                    v-model="menu.quantity"
                                    type="number"
                                    disabled
                                >
                                </v-text-field>
                              </div>
                              <div class="w-25 mt-5">
                                <v-text-field style="font-size: 20px"
                                    :label="$t('delivered_1')"
                                    hide-details
                                    dense
                                    outlined
                                    type="number"
                                    class="red--text text--darken-2 mr-2"
                                    v-model="menu.quantity_payed"
                                >
                                </v-text-field>
                              </div>
                            </v-row>
                          </template>
                        </v-col>
                      </v-row>
                    </v-card>
                    </v-row>
                  </template>

                  <template v-for="(foods, shift) in menusShift">
                    <v-col cols="12" class="red--text text--darken-2">{{shift}}</v-col>
                    <template v-for="(food, name) in foods">
                      <v-col cols="12" sm="4">
                        <v-card class="pa-4 fill-height red--text">
                          <h1 class="pb-1 red--text text--darken-2" ><b>{{translate(name)}}</b></h1>
                          <!--<template v-for="(food, i) in menu.meals.foods">
                              <v-col cols="12" class="py-1">{{i+1}}: {{translate(food.name)}}</v-col>
                          </template>-->
                          <v-text-field
                                  :label="$t('ordered_qty')"
                                  hide-details
                                  dense
                                  outlined
                                  class="py-2 red--text text--darken-2"
                                  v-model="food.quantity"
                                  disabled
                          >
                          </v-text-field>
                        </v-card>
                      </v-col>
                    </template>
                  </template>
                  <v-btn
                        v-show="!check"
                        :disabled="check"
                        fixed
                        bottom
                        v-if="selected && (menus.length || (menusShift && menusShift.length))"
                        width="400px"
                        class="mt-4"
                        :class="classConfirm"
                        height="52"
                        @click="menus.length ? pay() : payGroup()">
                      {{translate('Потврди испоруку')}}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
            <v-card v-if="lastPayed.length" style="position:fixed;bottom: 0; margin: 0 20px;margin: 0;height: 90px" :style="widthBar">
              <v-row>
                  <template v-for="(orders, i) in lastPayed.slice(1)">
                    <v-col cols="12" sm="4">
                      <div style="border-right: 2px solid rgba(128,128,128,0.51);height: 150px;margin-left: 20px">
                        <h1 style="color: #D32F2F;font-size: 21px" class="pl-2 pb-0">{{ i+1 }}. {{ translate(orders.user.name) }}  {{ translate(orders.user.lastname) }}</h1>
                        <template v-if="!user.user_wrong_location" v-for="(order, ii) in orders.menus">
                            <v-row class="ma-0 pa-2 py-0">
                                <h2 style="font-size: 17px"> {{ translate(order.meals.name) }} - {{ order.quantity }} kom</h2>
                             </v-row>
                        </template>
                      </div>
                    </v-col>
                  </template>
                </v-row>
            </v-card>
            <template v-if="reservedMeal && showReservedMeal">
              <div class="ma-0 pa-4 py-1" style="margin-top: -550px !important;">
                <div style="display: flex; justify-content: center; align-content: center;flex-wrap: wrap;">
                  <div>
                    <div>
                      <v-img v-if="showReservedMeal.image"
                          class="flex justify-center mt-12"
                          height="250"
                          width="250"
                          :src="
                            conf.apiUrlBase +
                              '/gallery/' +
                              $store.state.company.restaurant.id +
                              '/medium/' +
                              showReservedMeal.image.name
                            "
                      ></v-img>
                      <v-img class="flex justify-center rounded"
                             width="150" v-else height="150" :src="'https://cdn.vuetifyjs.com/images/cards/cooking.png'"></v-img>
                    </div>
                  </div>
                  <div class="text-center font-weight-bold naplata-sirina" style="font-size: 18px;border: 1px solid #dadada;border-bottom-right-radius: 12px;border-top-right-radius: 12px;border-top-left-radius: 0;background: #fff">
<!--                    <h1 class="pb-2 red&#45;&#45;text text&#45;&#45;darken-2">-->
<!--                    - {{ showReservedMeal.name }}-->
<!--                    </h1>-->
<!--                    <div style="display: flex;justify-content: center">-->
<!--                      <div style="width: 85%;text-align: left;padding: 10px;background: #fff;border: 1px solid #7e7e7e;border-radius: 2px">-->
<!--                        <p style="font-size: 22px" class="text&#45;&#45;darken-2">Садржи:</p>-->
<!--                        <p style="font-size: 19px" class="text&#45;&#45;darken-2" v-for="(sastojci,index) in showReservedMeal.foods">-->
<!--                          {{ index + 1}}. {{ sastojci.name }}-->
<!--                        </p>-->
<!--                      </div>-->
<!--                    </div>-->
                    <v-card style="border-left: none;border-right: none;border-bottom: none;border-radius: 12px;border-top-left-radius: 0" outlined width="42vw" class="pa-3 mt-0 pt-0 justify-center">
                      <v-row>
                        <v-col cols="10">
                          <h1 style="color: rgba(0,0,0,0.93);font-size: 21px;text-align: left" class="pl-2 pb-0">{{ showReservedMeal.name }}</h1>
                          <template v-for="(food, i) in showReservedMeal.foods">
                              <v-col style="text-align:left;color: rgba(0,0,0,0.4);font-size: 18px !important;font-family:'Poppins', Helvetica, sans-serif; !important;font-weight: 700 !important;" class="py-1 font-weight-medium" >{{i+1}}. {{translate(food.name)}}</v-col>
                          </template>
                        </v-col>
                      </v-row>
                    </v-card>
                  </div>
                </div>
              </div>
            </template>
        </div>
        <div style="position:absolute; right: 10px; top: 10px;">
            <v-btn @click="drawer = true" @keypress.enter.prevent="drawer = true" fab color="primary" class="float-right mr-7" >
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </div>


        <v-navigation-drawer
                v-model="drawer"
                absolute
                app
                right
                temporary
                hide-overlay
        >
            <v-row class="px-2">
                <v-col cols="12">
                    <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            format="DD.MM.YYYY"
                            max-width="290px"
                            min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    v-model="computedDateFormatted"
                                    :label="$t('date')"
                                    append-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details

                            ></v-text-field>
                        </template>
                        <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                        >
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                            >
                                {{$t('confirm')}}
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" class="pb-1 px-3 py-0">
                    <!--<input onblur="this.focus()" autofocus id="json" type="text" @input="onDecode"/>-->
                    <!-- <h2>QR Scanner</h2>-->
                    <qrcode-stream @decode="onDecode"></qrcode-stream>
                </v-col>
                <v-col cols="12" class="pb-0 px-3 py-0">
                    <v-alert
                            v-if="message.state"
                            dense
                            :type="message.color"
                    >
                        {{translate(message.text)}}
                    </v-alert>
                    <v-alert v-else :type="message.color" dense>{{ $t('read') }}</v-alert>
                </v-col>
                <v-col cols="12" class="pt-0">
                    <v-text-field
                            :label="translate('ID корисника')"
                            v-model="qrCode"
                            append-icon="mdi-calculator"
                            @click:append="show1 = !show1"
                            @keyup.enter="onDecode(false)"
                            :messages="$t('enter_id')"
                    ></v-text-field>
                    <input type="hidden" v-model="app_type">
                    <v-progress-linear
                            v-if="loader"
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                    ></v-progress-linear>
                </v-col>
                <v-col cols="12">
                    <!-- <div v-if="user" class="mb-4">
                        <h2>{{translate(user.name)}} {{translate(user.lastname)}}</h2>
                    </div> -->
                    <v-img :src="conf.apiUrlBase + '/gallery/'+ user.image_path" v-if="user && user.image_path"></v-img>
                    <!--<v-select
                            v-if="user"
                            :items="Array.from(new Set((user.orders || []).map(i => i.type)))"
                            :label="translate('Тип')"
                            v-model="selected"
                    ></v-select>-->
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </v-row>
</template>

<script>
    import axios from '../plugins/axios'
    import { QrcodeStream } from 'vue-qrcode-reader'
    import conf from '../config'
    import moment from "moment/moment";

    export default {
        components: {
            QrcodeStream
        },
        data() {
            return {
                sheet: true,
                moze: false,
                show_user_firm: true,
                conf: conf,
                drawer: true,
                date: new Date().toISOString().substr(0, 10),
                //date: '2021-01-10',
                menu: false,
                shifts:{'1-smena':"1. Смјена",'2-smena':"2. Смјена",'3-smena':"3. Смјена"},
                payment: {
                    status: false,
                    text: 'Успјешно испоручено',
                    color: 'success'
                },
                payment1: {
                  status: false,
                  text: 'Успјешно испоручено',
                  color: 'success'
                },
                message: {
                  text: 'Очитан корисник',
                  color: 'success',
                  state: false
                },
                qrCode: null,
                buttons: [
                    { key: "7", label: "7", color: "", class: "headline" },
                    { key: "8", label: "8", color: "", class: "headline" },
                    { key: "9", label: "9", color: "", class: "headline" },
                    { key: "4", label: "4", color: "", class: "headline" },
                    { key: "5", label: "5", color: "", class: "headline" },
                    { key: "6", label: "6", color: "", class: "headline" },
                    { key: "1", label: "1", color: "", class: "headline" },
                    { key: "2", label: "2", color: "", class: "headline" },
                    { key: "3", label: "3", color: "", class: "headline" },
                    { key: "c", label: "C", color: "grey lighten-2", class: "" },
                    { key: "0", label: "0", color: "", class: "headline" },
                    {
                        key: "enter",
                        label: "ENTER",
                        color: "grey lighten-2",
                        class: "",
                    }
                ],
                user: null,
                show1: false,
                loader: false,
                selected: null,
                company: null,
                calculatedQr: {
                    arr: [],
                    status: true,
                    json: null
                },
                timer: {
                    status: 0,
                    show: false
                },
                lastPayed: [],
                events: [],
                mealsForCurrentDay: '',
                allMeals: '',
                reservedMeal: false,
                showReservedMeal: '',
                app_type: '',
                taken_meal: ''
            }
        },
        created() {
          //this.onDecode()
          //this.downloadTimer()
          this.getMeals()
          this.app_type = 1
        },
        methods: {
            getMeals() {
              let restoran = ''
              if (this.$store.state.restaurant) {
                restoran = this.$store.state.restaurant.id
              } else {
                restoran = this.$store.state.company.restaurant.id
              }
              this.showReservedMeal = ''
              axios.post('/meals/reserved-meal?restoran=' + restoran).then(({data}) => {
                this.allMeals = data.data
                data.data.filter(meal => {
                  if(this.date == meal.date) {
                    this.mealsForCurrentDay = meal.meals
                    meal.meals.filter(m => {
                      if (m.can_reserve) {
                        this.showReservedMeal = m
                      }
                    })
                  }
                })
              })
            },
            test(event, e) {
                console.log(event)
                console.log(e)
                //this.drawer = true
            },

            InputKey(key) {
                if(key === 'c') {
                    this.idUser = ''
                }
                else if(key === 'enter') {

                }
                else {
                    this.idUser += key
                }
            },
            orderFromOneShiftToAnother(orders, text, color) {
                let reservedMeal = {}
                axios.post('/orders/set-reserved-meal', {
                  state: 'payed',
                  orders: orders,
                }).then(res => {
                  this.payment1.text = text
                  this.payment1.color = color
                  this.payment1.status = true
                  reservedMeal = [{id: res.data.data.id, quantity: res.data.data.quantity, quantity_payed: res.data.data.quantity_payed}]
                }).catch(err => {
                  this.payment1.text = 'Грешка, молим Вас покушајте поново'
                  this.payment1.color = color
                }).finally((res) => {
                  this.qrCode = null
                  this.payment1.status = true
                  this.drawer = true
                  this.reservedMeal = true
                  this.payReservedWrongShift(reservedMeal, color, text)
                })
            },
            payReservedWrongShift(reservedMeal, color, text) {
              if(this.timer.show) return
              axios.post('/orders/pay', {
                state: 'payed',
                orders: reservedMeal,
              }).then(res => {
                this.payment1.text = text
                this.payment1.color = color
              }).catch(err => {
                this.payment1.text = 'Грешка, молим Вас покушајте поново'
                this.payment1.color = 'error'
              }).finally(() => {
                this.qrCode = null
                this.payment1.status = true
                this.moze = true
                this.drawer = true

                this.showReservedMeal.meals = this.showReservedMeal
                this.user.orders.push(this.showReservedMeal)
                this.menus.push(this.showReservedMeal)

                let menu = {}
                menu.menus = [... this.menus]
                menu.user = {...this.user}
                this.lastPayed.push(menu)
                if(this.lastPayed.length > 3) {
                  this.lastPayed.pop();
                  this.lastPayed.unshift(menu)
                } else {
                  this.lastPayed.unshift(menu)
                }
                this.downloadTimer()
                this.$forceUpdate()
              })
            },
            allowMealTakeoverFromSameCompanyToAnotherCompany(restoran, kompanija) {
              let parentID = null;
              let worker = null;
              if (restoran) {
                worker = restoran.settings.worker ? restoran.settings.worker : false
                parentID = restoran.parent_id
              } else {
                worker = kompanija.restaurant.settings.worker ? kompanija.restaurant.settings.worker : false
              }
              if(kompanija) {
                if (kompanija.parent_id) {
                  parentID = kompanija.parent_id
                } else {
                  parentID = kompanija.id
                }
              }
              let podaci = {}
              podaci['company_permission'] = worker
              podaci['parent'] = parentID

              return podaci;
            },
            onDecode (decodedString) {
              if(this.timer.show) return
              //this.selected = null
              let isporuka = ''
              let company_permission = ''
              let parentID = '';

              // Дозволити преузмање оброка радницима исте фирме у другој пословници
              let permits = this.allowMealTakeoverFromSameCompanyToAnotherCompany(this.$store.state.restaurant, this.$store.state.company)
              company_permission = permits.company_permission
              parentID = permits.parent

              // ID od isporuke - user_id od isporuke
              if(this.$store.state.company) {
                isporuka = this.$store.state.user.companies[0].id
              } else {
                isporuka = ''
              }

              this.loader = true
              this.payment.status = false
              this.qrCode = decodedString ? JSON.parse(decodedString).id : this.qrCode

              // dozvola porucivanja obraka iz jedne smene, a preuzimanje u drugoj
              let companySettings = true // default je dozvoljeno
              let shiftOne = null
              let shiftTwo = null
              let shiftThree = null
              if (this.$store.state.company && this.$store.state.company.settings) {
                if (typeof this.$store.state.company.settings === 'string') { // check for string!
                  companySettings = this.$store.state.company && this.$store.state.company.settings ? JSON.parse(this.$store.state.company.settings).order_from_one_shift_to_another : true
                  shiftOne = this.$store.state.company && this.$store.state.company.settings ? JSON.parse(this.$store.state.company.settings).shift_one : null
                  shiftTwo = this.$store.state.company && this.$store.state.company.settings ? JSON.parse(this.$store.state.company.settings).shift_two : null
                  shiftThree = this.$store.state.company && this.$store.state.company.settings ? JSON.parse(this.$store.state.company.settings).shift_three : null
                } else {
                  companySettings = this.$store.state.company && this.$store.state.company.settings ? this.$store.state.company.settings.order_from_one_shift_to_another : true
                  shiftOne = this.$store.state.company && this.$store.state.company.settings ? this.$store.state.company.settings.shift_one : null
                  shiftTwo = this.$store.state.company && this.$store.state.company.settings ? this.$store.state.company.settings.shift_two : null
                  shiftThree = this.$store.state.company && this.$store.state.company.settings ? this.$store.state.company.settings.shift_three : null
                }
              }

              if(!this.qrCode) return

              let path = ''
              if (this.$store.state.company.type == "group") {
                path = '/user/' + this.qrCode + '/group?date=' + this.date + '&isporuka=' + isporuka + '&company_permission=' + company_permission + '&parent_id=' + parentID + '&recipient=' + this.$store.state.user.id
              } else {
                path = '/user/' + this.qrCode + '?date=' + this.date + '&isporuka=' + isporuka + '&company_permission=' + company_permission + '&parent_id=' + parentID + '&recipient=' + this.$store.state.user.id
              }

              axios.get(path).then(res => {
                if(! res.data.data.hasOwnProperty('data')) {
                  this.user = res.data.data
                  // provera da li tip rucak i da li placeno - porudzbe (variabla)
                  let porudzbe = '';
                  if (this.$store.state.company.type == "group") {
                    porudzbe = ! this.user.orders.find(i => i.type === 'rucak' && i.state === 1)
                  } else {
                    porudzbe = ! this.user.orders.find(i => i.type === 'rucak' && i.state === 'reserved')
                  }

                  if(this.user.user_wrong_location) {
                    this.payment.text = 'Корисник ' + this.user.name + ' ' + this.user.lastname + ' је на погрешној локацији'
                    this.payment.color = 'error'
                    this.payment.status = true
                    this.reservedMeal = false
                    new Audio('./sounds/pogresna-lokacija.mp4').play();
                    return
                  }

                  if(porudzbe) {
                    if(! this.user.orders.length) {
                      if(this.$store.state.company && this.$store.state.company.restaurant && this.$store.state.company.restaurant.settings.can_reserve) {
                        this.payment1.text = 'Нисте поручили на вријеме, можете преузети  резервни оброк'
                        this.message.color = 'error'
                        this.payment1.status = true
                        this.reservedMeal = true
                        let reservedMeal = {}
                        let orders = {
                          user_id: Number(this.qrCode),
                          meal_id: this.showReservedMeal.id,
                          date: this.date,
                          quantity: 1,
                          quantity_payed: 1,
                          restaurant_id: this.showReservedMeal.restaurant_id,
                          company_id: this.$store.state.company.id,
                          type: 2
                        }
                        if (this.showReservedMeal == '') {
                          this.payment.text = this.$t('reserve_meal_not')
                          this.payment.color = 'error'
                          this.payment1.text = ''
                          this.payment1.status = false
                          // this.loader = false
                          setTimeout(() => {
                            window.location.reload()
                          }, 4000)
                          return;
                        }
                        axios.post('/orders/set-reserved-meal', {
                          state: 'payed',
                          orders: orders,
                        }).then(res => {
                          this.payment1.text = 'Нисте поручили на вријеме, можете преузети  резервни оброк'
                          this.payment.color = 'success'
                          this.payment1.status = true
                          reservedMeal =  [{id: res.data.data.id, quantity: res.data.data.quantity, quantity_payed: res.data.data.quantity_payed}]
                        }).catch(err => {
                          this.payment.text = 'Грешка, молим Вас покушајте поново'
                          this.payment.color = 'error'
                        }).finally((res) => {
                          this.qrCode = null
                          this.payment.status = true
                          this.drawer = true
                          this.payReserved(reservedMeal)
                        })

                        return
                      } else {
                        this.payment.text = 'Није направљена поруџбина за одабрани датум'
                        this.payment.color = 'error'
                        this.payment.status = true
                        this.payment1.text = ''
                        this.payment1.status = false
                        this.reservedMeal = false
                        /* setTimeout(() => {
                            this.payment.status = false
                        }, 5000) */
                        new Audio('./sounds/greska-nije-izvrsena-narudzba.mp4').play();
                        return
                      }
                    } else {
                      let message = ''
                      this.payment1.text = ''
                      switch(this.user.orders[0].state) {
                        case 'payed-all': message = this.$t('taken')
                          break
                        case 'payed-part': message = this.$t('taken_part')
                          break
                        case 'payed-over': message = this.$t('taken')
                          break
                        case 'canceled': message = this.$t('cancels')
                          break
                        default: message = this.$t('error')
                      }
                      this.payment.text = message + ', већ сте преузели оброк!'
                      this.payment.color = 'error'
                      this.payment1.text = 'већ сте преузели оброк!'
                      this.payment1.status = false
                      this.payment.status = true
                      this.reservedMeal = false
                      new Audio('./sounds/greska-vec-preuzet-obrok.mp4').play();
                      return
                    }
                  }

                  if(! companySettings) {
                    if (this.showReservedMeal == ''  && (this.$store.state.company.restaurant && this.$store.state.company.restaurant.can_reserve)) {
                      this.payment.text = this.$t('reserve_meal_not')
                      this.payment.color = 'error'
                      this.payment1.text = ''
                      this.payment.status = true
                      this.show_user_firm = false
                      setTimeout(() => {
                        window.location.reload()
                      }, 4000)
                      return;
                    }
                    let orders = {
                      user_id: Number(this.qrCode),
                      meal_id: this.showReservedMeal.id,
                      date: this.date,
                      quantity: 1,
                      quantity_payed: 1,
                      restaurant_id: this.showReservedMeal.restaurant_id,
                      company_id: this.$store.state.company.id,
                      type: 2,
                    }
                    let smena = []
                    this.user.orders.filter(item => {
                      smena.push(item.shift)
                    })
                    // delivery_from - vreme OD
                    // delivery_from1 - vreme DO
                    if(moment().hour() >= shiftOne.delivery_from_hour && moment().minute() >= parseInt(shiftOne.delivery_from_min)   && moment().hour() <= shiftOne.delivery_to_hour) {
                      let canF = false
                      this.user.orders.filter(item => {
                        if(item.shift == '1-smena') {  canF = true; }
                      })
                      if (canF) {
                        if(this.check) {
                          if (this.$store.state.company.type == "group") {
                            this.payFood()
                          } else {
                            this.pay()
                          }
                        }
                      } else {
                        orders.shift = '1-smena'
                        this.show_user_firm = false
                        this.menus[0].shift = '1-smena'
                        // this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 1. смјену. Можете преузети замјенски оброк.', 'error')
                        this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 1. смјену.', 'error')
                      }
                    }
                    else if(moment().hour() >= shiftTwo.delivery_from_hour && moment().minute() >= parseInt(shiftTwo.delivery_from_min)  && moment().hour() <= shiftTwo.delivery_to_hour) {
                      let canF = false
                      this.user.orders.filter(item => {
                        if(item.shift == '2-smena') {  canF = true; }
                      })
                      if (canF) {
                        if(this.check) {
                          if (this.$store.state.company.type == "group") {
                            this.payFood()
                          } else {
                            this.pay()
                          }
                        }
                      } else {
                        orders.shift = '2-smena'
                        this.show_user_firm = false
                        this.menus[0].shift = '2-smena'
                        // this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 2. смјену. Можете преузети замјенски оброк.', 'error')
                        this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 2. смјену.', 'error')
                      }
                    }
                    else if(moment().hour() >= parseInt(shiftThree.delivery_from_hour) && moment().minute() >= parseInt(shiftThree.delivery_from_min) && moment().hour() <= parseInt(shiftThree.delivery_to_hour)) {
                      let canF = false
                      this.user.orders.filter(item => {
                        if(item.shift == '3-smena') { canF = true; }
                      })
                      if (canF) {
                        if(this.check) {
                          if (this.$store.state.company.type == "group") {
                            this.payFood()
                          } else {
                            this.pay()
                          }
                        }
                      } else {
                        orders.shift = '3-smena'
                        this.show_user_firm = false
                        this.menus[0].shift = '3-smena'
                        // this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 3. смјену. Можете преузети замјенски оброк.', 'error')
                        this.orderFromOneShiftToAnother(orders, 'Немате наруџбу за 3. смјену.', 'error')
                      }
                    } else if(
                        (moment().hour() >= shiftOne.delivery_from_hour && moment().hour() > shiftOne.delivery_to_hour) ||
                        (moment().hour() >= shiftTwo.delivery_from_hour && moment().hour() > shiftTwo.delivery_to_hour) ||
                        (moment().hour() >= shiftThree.delivery_from_hour && moment().hour() > shiftThree.delivery_to_hour)
                    ) {
                      this.payment.text = 'Молимо Вас дођите у термину за поделу оброка.'
                      this.payment.color = 'error'
                      this.payment.status = true
                      this.show_user_firm = false
                    }
                    this.show_user_firm = true
                    return;
                  }
                }
                else {
                  this.company = res.data.data.data
                  this.user = res.data.data.user
                }
                this.message.text = 'Очитан корисник'
                this.message.color = 'success'
                this.selected = 'rucak'
                this.message.state = true
                //this.selected = 'dorucak'
                this.reservedMeal = false

                setTimeout(() => {
                  this.message.state = false
                }, 5000)
                if(this.check) {
                  if (this.$store.state.company.type == "group") {
                    this.payFood()
                  } else {
                    this.pay()
                  }
                }
                if(this.checkForCompany) {
                  if (this.$store.state.company.type == "group") {
                    this.payFood()
                  } else {
                    this.pay()
                  }
                }
              }).catch(res => {
                this.user = null
                this.message.color = 'error'
                this.message.text = 'Не постоји корисник'

                new Audio('./sounds/greska-neispravna-autentifikacija.mp4').play();
                return
              }).finally(() => {
                this.loader = false
                this.qrCode = null
              })
            },
            pay() {
                if(this.timer.show) return

                let orders =  this.menus.map(i => {
                    return {id: i.id, quantity: i.quantity, quantity_payed: i.quantity_payed}
                })
                axios.post('/orders/pay', {
                    app_type: this.app_type,
                    state: 'payed',
                    orders: orders,
                }).then(res => {
                    this.payment.text = 'Успешно испоручено'
                    this.payment.color = 'success'
                  // this.mealsForCurrentDay.filter(itm => {
                  //   if (this.showReservedMeal.id == itm.id) {
                  //     itm.meals = itm
                  //     this.menus.push(itm)
                  //   }
                  // })
                }).catch(err => {
                    this.payment.text = 'Грешка, молим Вас покушајте поново'
                    this.payment.color = 'error'
                }).finally(() => {
                    this.qrCode = null
                    //this.selected = null
                    //this.user = null
                    this.payment.status = true
                    this.drawer = true


                    let menu = {}
                    menu.menus = [...this.menus]
                    menu.user = {...this.user}
                    if(this.lastPayed.length > 3) {
                        this.lastPayed.pop();
                        this.lastPayed.unshift(menu)
                    }
                    else {
                        this.lastPayed.unshift(menu)
                    }
                    this.$forceUpdate()
                    this.downloadTimer()

                })
            },
            payFood() {
              if(this.timer.show) return

              let orders =  this.menus.map(i => {
                return {id: i.id, quantity: i.quantity, quantity_payed: i.quantity_payed}
              })
              axios.post('/orders/pay-food', {
                app_type: this.app_type,
                state: 'payed',
                orders: orders,
              }).then(res => {
                this.payment.text = 'Успешно испоручено'
                this.payment.color = 'success'
              }).catch(err => {
                this.payment.text = 'Грешка, молим Вас покушајте поново'
                this.payment.color = 'error'
              }).finally(() => {
                this.qrCode = null
                this.payment.status = true
                this.drawer = true
                this.menus.filter(menu => {
                  menu.meals = menu.transaction
                  menu.meals.foods = []
                  menu.transaction.filter(item => {
                    menu.meals.foods.push(item.food)
                  })
                })
                let menu = {}
                menu.menus = [...this.menus]
                menu.user = {...this.user}
                if(this.lastPayed.length > 3) {
                  this.lastPayed.pop();
                  this.lastPayed.unshift(menu)
                }
                else {
                  this.lastPayed.unshift(menu)
                }
                this.$forceUpdate()
                this.downloadTimer()

              })
            },
            payReserved(reservedMeal) {
              if(this.timer.show) return
              axios.post('/orders/pay', {
                state: 'payed',
                orders: reservedMeal,
              }).then(res => {
                this.payment.text = 'Успешно испоручено'
                this.payment.color = 'success'
              }).catch(err => {
                this.payment.text = 'Грешка, молим Вас покушајте поново'
                this.payment.color = 'error'
              }).finally(() => {
                this.qrCode = null
                //this.selected = null
                //this.user = null
                this.payment.status = true
                this.drawer = true

                this.showReservedMeal.meals = this.showReservedMeal
                this.user.orders.push(this.showReservedMeal)
                this.menus.push(this.showReservedMeal)

                let menu = {}
                menu.menus = [... this.menus]
                menu.user = {...this.user}
                this.lastPayed.push(menu)
                if(this.lastPayed.length > 3) {
                  this.lastPayed.pop();
                  this.lastPayed.unshift(menu)
                }
                else {
                  this.lastPayed.unshift(menu)
                }

                this.downloadTimer()
                this.$forceUpdate()
              })
            },
            downloadTimer() {
                let timeleft = this.secondTimer;
                this.timer.show = true
                setInterval(() => {
                    if(timeleft <= 0){
                        clearInterval(this.downloadTimer);
                        this.timer.show = false
                    } else {
                        this.timer.status = timeleft
                    }
                    timeleft -= 1;
                }, 1000)

            },
            payGroup() {
                let orders = []
                for(let shift in this.menusShift) {
                    for(let shift2 in this.menusShift[shift]) {
                        this.menusShift[shift][shift2].ids.forEach(i => {
                            orders.push({id: i})
                        })
                    }
                }

                axios.post('/orders/pay', {
                    state: 'payed',
                    orders: orders,
                }).then(res => {
                    this.payment.text = 'Успешно испоручено'
                    this.payment.color = 'success'
                }).catch(err => {
                    this.payment.text = 'Грешка, молим Вас покушајте поново'
                    this.payment.color = 'error'
                }).finally(() => {
                    this.qrCode = null
                    //this.selected = null
                    //this.user = null
                    this.payment.status = true
                    this.drawer = true
                })
            },
          formatDate(value) {
            return moment(value).format('DD.MM.YYYY.')
          },
        },

        computed: {
          shiftOneTime() {
            if(this.$store.state.company.settings) {
              let settings = ''
              if (typeof this.$store.state.company.settings === 'string') { // check for string!
                 settings = JSON.parse(this.$store.state.company.settings)
              } else {
                 settings = this.$store.state.company.settings
              }
              console.log(settings)
              if (settings && settings.shift_one) {
                return settings.shift_one.delivery_from_hour + ':' + settings.shift_one.delivery_from_min + ' - ' + settings.shift_one.delivery_to_hour + ':' + settings.shift_one.delivery_to_min
              } else {
                return false
              }
            }
          },
          shiftTwoTime() {
            if(this.$store.state.company.settings) {
              let settings = ''
              if (typeof this.$store.state.company.settings === 'string') { // check for string!
                settings = JSON.parse(this.$store.state.company.settings)
              } else {
                settings = this.$store.state.company.settings
              }
              console.log(settings)
              if (settings && settings.shift_two) {
                return settings.shift_two.delivery_from_hour + ':' + settings.shift_two.delivery_from_min + ' - ' + settings.shift_two.delivery_to_hour + ':' + settings.shift_two.delivery_to_min
              } else {
                return false
              }
            }
          },
          shiftThreeTime() {
            if(this.$store.state.company.settings) {
              let settings = ''
              if (typeof this.$store.state.company.settings === 'string') { // check for string!
                settings = JSON.parse(this.$store.state.company.settings)
              } else {
                settings = this.$store.state.company.settings
              }
              console.log(settings)
              if (settings && settings.shift_three) {
                return settings.shift_three.delivery_from_hour + ':' + settings.shift_three.delivery_from_min + ' - ' + settings.shift_three.delivery_to_hour + ':' + settings.shift_three.delivery_to_min
              } else {
                return false
              }
            }
          },
          computedDateFormatted () {
            return this.formatDate(this.date)
          },
          checkForCompany() {
            // let settings = this.$store.state.company.settings
            let settings = ''
            if (typeof this.$store.state.company.settings === 'string') { // check for string!
              settings = JSON.parse(this.$store.state.company.settings)
            } else {
              settings = this.$store.state.company.settings
            }
            console.log(settings)
            if (settings) {
              let checkCompany = settings.confirm_delivery
              if(checkCompany && (this.user && this.user.type === 'company'))  return true
            }
          },
            widthBar() {
                this.drawer ? 'width: calc(100% - 512px)' : 'width: calc(100% - 256px);'

                if(this.drawer && this.$store.state.drawer) return 'width: calc(100% - 512px)';
                else if(this.drawer || this.$store.state.drawer) return 'width: calc(100% - 256px)';
                else return 'width: 100%';
            },
            classConfirm(){
                if(this.timer.show){
                    return 'gray black--text'
                }else{
                    return 'green white--text'

                }
            },
            secondTimer() {
                let second = this.$store.state.company
                    ? this.$store.state.company?.restaurant?.settings?.timer
                    : this.$store.state.restaurant?.settings?.timer

                if(second) return second
                return 10
            },
            check() {
              let check = this.$store.state.company
                  ? this.$store.state.company?.restaurant?.settings?.confirm_delivery
                  : this.$store.state.restaurant?.settings?.confirm_delivery

              if(check && (this.user && this.user.type === 'customer'))  return true
            },
            menus() {
            let porudzbe = ''
              if (this.$store.state.company.type == "group") {
                porudzbe = this.user?.orders ? this.user.orders.filter(i => i.type === 'rucak' && i.state === 1) : []
                // porudzbe = this.user?.orders ? this.user.orders.filter(i => i.state === 1) : []
              } else {
                porudzbe = this.user?.orders ? this.user.orders.filter(i => i.type === 'rucak' && i.state === 'reserved') : []
              }
                let menu = porudzbe

                return menu.map(i => {
                    i.quantity_payed = i.quantity
                    return i
                })
            },
            menusShift() {
                return this.company
            }
        },
        watch: {
            date() {
              this.showReservedMeal = ''
              if (this.allMeals) {
                this.allMeals.filter(meal => {
                  if (meal.date == this.date) {
                    this.mealsForCurrentDay = meal.meals
                    meal.meals.filter(m => {
                      if (m.can_reserve) {
                        this.showReservedMeal = m
                      }
                    })
                  }
                })
              }

              return this.mealsForCurrentDay
            },
            'calculatedQr.status'() {
                if(this.calculatedQr.json && typeof JSON.parse(this.calculatedQr.json) !== 'number') {
                    this.onDecode(this.calculatedQr.json)
                }
            },
            'message.state'() {
                setTimeout(() => {
                    this.message.state = false
                }, 3000)

            },
            'payment.status'() {
                setTimeout(() => {
                    this.message.state = false
                }, 1500)
            }
        },
        mounted() {
            let _calculatedQr = this.calculatedQr

            document.addEventListener('keypress', function(event) {
                if(event.keyCode === 123) {
                    _calculatedQr.status = true
                    _calculatedQr.json = null
                    _calculatedQr.arr = []
                }
                if(_calculatedQr.status) {
                    if(event.keyCode !== 13)_calculatedQr.arr.push(event.key)
                    if(event.keyCode === 13) {
                        _calculatedQr.json = _calculatedQr.arr.join('')
                        _calculatedQr.status = false
                    }
                }

            }, true);
        },
        beforeDestroy() {
            document.removeEventListener("keypress", function(event) {
                alert('izbrisan eventlistener')
            });
        }
    }
</script>
<style scoped>

  .shift-title-p {
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding: 0 200px;
  }
  .div-okolo {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 5px;
    border-radius: 12px;
    background: rgba(251, 109, 59, 0.05);
    margin-bottom: 10px;
  }
.v-application .error--text {
    color: rgb(251, 109, 59) !important;
    caret-color: rgb(251, 109, 59) !important;
}
</style>
<style>
    .okolo {
      width: calc(100% - 256px); margin: 0 20px
    }
    .v-alert__icon.v-icon {
      font-size: 35px !important;
    }
    .custom-button {
        width: 60px;
        height: 60px;
        background-color: blue;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .payment-container{
        background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('../assets/bg-isporuka.jpg');
        background-size: cover;
        height: 100%;
    }

.kapica {
  position: absolute;
  left: -23px;
  top: -10px;
  width: 36px;
  transform: rotate(-30deg);
}
.shift-title {
  font-family: "Gagalin", Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 400;
}
.relative {
  position: relative;
  width: max-content;
}
.shift1 {
  color: #c20606;
}
.shift2 {
  color: #99ad16;
}
.shift3 {
  color: #ff914d;
}
.naplata-sirina {
  width: 50%;
}
@media(max-width: 767px) {
  body div, p {
    /*font-size: 95% !important;*/
  }
  .okolo {
    width: 100%
  }
  .naplata-sirina {
    width: 100%;
  }
}
    .v-application .text-h6 {
        font-family: "Poppins", Helvetica, sans-serif !important;
    }
</style>
