<template>
<div style="width: 95%;margin: 10px auto">
  <v-toolbar flat>
    <v-toolbar-title>{{ $t("companies") }}</v-toolbar-title>
    <v-divider class="mx-4" inset vertical></v-divider>
    <div style="width: 50%;margin-left: auto">
    <v-text-field
      class="pr-4 pb-2"
      v-model="search"
      append-icon="mdi-magnify"
      :label="$t('search')"
      single-line
      hide-details
      dense
    ></v-text-field>
    </div>
    <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          class="mb-2 mr-2"
          v-bind="attrs"
          v-on="on"
          small
        @click="print('print')" >
       <v-icon class="mr-2">mdi-printer</v-icon>
       <span id="stampaj">{{ $t("print") }}</span>
     </v-btn>
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          class="mb-2"
          v-bind="attrs"
          v-on="on"
          small
        >
          <v-icon>mdi-plus</v-icon>
          {{$t("add_company")}}
        </v-btn>

      </template>

      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text"
            >{{ formTitle }} | ID: {{ editedItem.id }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-container style="padding-bottom: 10px !important;">
            <v-row>
              <v-row class="okolo">
                <v-col sm="12" lg="6">
                  <v-switch
                    v-model="editedItem.state"
                    inset
                    :label="$t('active')"
                    :false-value="0"
                    :true-value="1"
                    dense
                  ></v-switch>
                </v-col>
                <v-col sm="12" lg="6">

                  <v-switch
                      v-model="editedItem.is_credit"
                      inset
                      :label="$t('credit_only')"
                      :false-value="0"
                      :true-value="1"
                      dense
                  ></v-switch>
<!--                  <v-switch-->
<!--                      v-model="editedItem.contract"-->
<!--                      inset-->
<!--                      :label="$t('company_with_contract')"-->
<!--                      :false-value="0"-->
<!--                      :true-value="1"-->
<!--                      dense-->
<!--                  ></v-switch>-->
                </v-col>
              </v-row>
              <v-row class="okolo">
              <v-col sm="12" lg="6">
                <v-switch
                  v-model="editedItem.checkReport"
                  inset
                  :label="$t('can_see_report_taken_meals')"
                  :false-value="0"
                  :true-value="1"
                  dense
                ></v-switch>
              </v-col>
              <v-col sm="12" lg="6">
                <v-radio-group 
                    v-model='editedItem.contract'
                >
                  <v-radio :value="1" :label="$t('company_with_contract')"></v-radio>
                  <v-radio :value="0" :label="$t('company_without_contract')"></v-radio>
                  <v-radio :value="2" :label="translate('Сала за ручавање')"></v-radio>
                </v-radio-group>
              </v-col>
              </v-row>
              <v-col sm="12" lg="12">
                <v-switch :title="'Izabranim firmama i radnicima omoguci narucivanje mimo vremensmog limita'"
                    v-if="$store.state.restaurant && $store.state.restaurant.settings.no_time_limit"
                    v-model="editedItem.no_time_limit"
                    inset
                    :label="$t('no_time_limit')"
                    :false-value="0"
                    :true-value="1"
                    dense
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[$t('mandatory_field')]"
                  :label="$t('*title')"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-select
                  v-model="editedItem.type"
                  :items="meniType"
                  :item-text="item => $t(item.name)"
                  item-value="name"
                  :label="$t('available_menu_type_for_comp')"
                  :rules="[$t('mandatory_field')]"
                ></v-select>
              </v-col>

              <v-col v-if="desserts.length" cols="12">
                <v-select
                  v-model="editedItem.parent"
                  :items="[{name:'',id:null}].concat(desserts.filter((item) => item.id))"
                  :label="$t('parent_company')"
                  item-text="name"
                  item-id="id"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.city"
                  :label="$t('city')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.address"
                  :label="$t('address')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.phone"
                  :label="$t('phone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.email"
                  :label="$t('email')"
                ></v-text-field>
              </v-col>
              <v-row class="px-3">
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.lib"
                    :label="$t('jib')"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="editedItem.pib"
                    :label="$t('pib')"
                  ></v-text-field>
                </v-col>
              </v-row>
<!--                  <v-col cols="12" class="font-weight-bold pl-1 pb-0 mb-0">-->
<!--                    Локација (координате):-->
<!--                  </v-col>-->
<!--                  <v-row class="pl-2 pr-2">-->
<!--                    <v-col cols="6">-->
<!--                      <v-text-field-->
<!--                          v-model="editedItem.lat"-->
<!--                          :label="translate('LAT')"-->
<!--                          type="number"-->
<!--                      ></v-text-field>-->
<!--                    </v-col>-->
<!--                    <v-col cols="6">-->
<!--                      <v-text-field-->
<!--                          v-model="editedItem.long"-->
<!--                          :label="translate('LONG')"-->
<!--                          type="number"-->
<!--                      ></v-text-field>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
              <v-col cols="12">
                <v-textarea
                  class="polje"
                  v-model="editedItem.description"
                  :label="$t('description')"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <b>{{ $t("mark_shift_comp_recieves_meals") }}</b> <br><br>
<!--                <b>{{ $t("1st_shift_restaurant_menu") }}</b>-->
                <v-col cols="12">
                  <v-row style="align-content: center">
                      <b>{{ $t("shit_one") }}</b>
                      <v-radio-group style="margin-top: 1px;margin-left: 35px" v-model="editedItem.shift_one" row>
                          <v-radio
                                  :label="$t('yes')"
                                  :value="1"
                          ></v-radio>
                          <!--                  <v-radio-->
                          <!--                    :label="$t('shit_two')"-->
                          <!--                    :value="2"-->
                          <!--                  ></v-radio>-->
                          <!--                  <v-radio-->
                          <!--                    :label="$t('shit_three')"-->
                          <!--                    :value="3"-->
                          <!--                  ></v-radio>-->
                          <v-radio
                                  :label="$t('closed')"
                                  :value="0"
                          ></v-radio>
                      </v-radio-group>
                  </v-row>
  <!--                <b>{{ $t("2nd_shift_restaurant_menu") }}</b>-->
                  <v-row style="align-content: center">
                    <b>{{ $t("shit_two") }}</b>
                    <v-radio-group style="margin-top: 1px;margin-left: 30px" v-model="editedItem.shift_two" row>
  <!--                  <v-radio-->
  <!--                    :label="$t('shit_one')"-->
  <!--                    :value="1"-->
  <!--                  ></v-radio>-->
                    <v-radio
                      :label="$t('yes')"
                      :value="2"
                    ></v-radio>
  <!--                  <v-radio-->
  <!--                    :label="$t('shit_three')"-->
  <!--                    :value="3"-->
  <!--                  ></v-radio>-->
                    <v-radio
                      :label="$t('closed')"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                  </v-row>
  <!--                <b>{{ $t("3rd_shift_restaurant_menu") }}</b>-->
                  <v-row style="align-content: center">
                  <b>{{ $t("shit_three") }}</b>
                  <v-radio-group style="margin-top: 1px;margin-left: 30px" v-model="editedItem.shift_three" row>
<!--                  <v-radio-->
<!--                    :label="$t('shit_one')"-->
<!--                    :value="1"-->
<!--                  ></v-radio>-->
<!--                  <v-radio-->
<!--                    :label="$t('shit_two')"-->
<!--                    :value="2"-->
<!--                  ></v-radio>-->
                  <v-radio
                    :label="$t('yes')"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    :label="$t('closed')"
                    :value="0"
                  ></v-radio>
                </v-radio-group>
                </v-row>
                </v-col>
              </v-col>
              <v-col cols="12">
                <a href="" @click.prevent="openModalSettings = true">
                  <v-icon :title="$t('settings')" small class="mr-2"> mdi-cog-outline</v-icon>Ostala podesavanja
                </a>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  color="primary" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn style="border-radius: 10px" color="primary" dark @click="save">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("delete_confirmation")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >{{ $t("confirm") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
  <v-data-table style="margin-top: 5px"
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
    id="print"
    dense
    show-expand
  >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
      {{$t(header.text)}}
    </template>
    <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
      {{translate(item[values.value])}}
    </template>
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.children && item.children.length">
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
          dark
          small
          rounded
          @click="expand(true)"
          v-if="!isExpanded"
        >
        <v-icon>mdi-arrow-down</v-icon>
          {{ $t("branches") }}
        </v-btn>
        <v-btn color="primary" dark small rounded @click="expand(false)" v-else>
          <v-icon>mdi-arrow-up</v-icon>
          {{ $t("hide") }}
        </v-btn>
      </template>
    </template>

    <template v-slot:expanded-item="{ item, isExpanded, expand }">
      <template v-if="item.children">
        <td :colspan="headers.length" class="pa-0">
          <v-data-table
            dense
            :headers="headers"
            class="elevation-1 grey lighten-4"
            :items="item.children || []"
            :items-per-page="item.children.length"
            hide-default-footer
            hide-default-header
          >
            <v-spacer></v-spacer>
            <template v-slot:item.is_credit="{ item, index }">
              <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
                  <span class="d-flex">
                    <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
                    <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
                  </span>
              </div>
            </template>
            <template v-slot:item.name="{ item, index }">
              {{ translate(item.name) }}
            </template>
            <template v-slot:item.type="{ item, index }">
              {{ $t(item.type) }}
            </template>
            <template v-slot:item.actions="{ item, index }">
              <v-btn
                small
                color="primary"
                text
                class="mx-2"
                :to="{ name: 'firma-id-korisnici', params: { id: item.id, name:item.name, parent_id:item.parent_id } }"
              >
                {{ $t("users") }} {{ item.users_count }}
              </v-btn>
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </td>
      </template>
    </template>
    <template v-slot:item.is_credit="{ item, index }">
      <div v-if="$store.state.user.type == 'restaurant' || $store.state.user.type == 'admin'" class="text-right">
        <span class="d-flex">
          <v-checkbox v-model="item.is_credit" @change="setCredit(item, index)"></v-checkbox>
          <v-icon small @click="openCredit(item)" style="color: #1e7e34;margin-top: -14px">mdi-currency-eur</v-icon>
        </span>
      </div>
    </template>
    <template v-slot:item.type="{ item, index }">
      {{ $t(item.type) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        color="primary"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name, parent_id:item.parent_id } }"
        v-if="
          (item.children && item.children.length == 0) ||
          !item.hasOwnProperty('children')
        "
      >
        {{ $t("users") }} {{ item.users_count }}
      </v-btn>
      <v-btn
        small
        color="primary"
        text
        class="mx-2"
        :to="{ name: 'firma-id-korisnici', params: { id: item.id,name:item.name } }"
        v-else
      >
        {{ $t("users") }}
        {{
          (item.children || []).reduce((sum, item) => {
            return item.users_count?sum + item.users_count:sum;
          }, 0)
        }}
      </v-btn>
      <v-icon :title="$t('edit')" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon :title="$t('settings')" small class="mr-2" @click="openSettings(item)"> mdi-cog-outline</v-icon>
      <v-icon :title="$t('delete')" small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
        {{ $t("reset") }}
      </v-btn>
    </template>
  </v-data-table>
  <v-dialog v-model="openModalAddCredit" max-width="700px" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%">
        <span style="font-size: 20px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ canSeeStoredCredit ? $t("credit") + ' ' + (modalCreditItems.company_id ? '| ID: ' +  modalCreditItems.id : '') : $t("add_credit") }}
          <span>
             <v-btn :style="canSeeStoredCredit ? 'background:#fff; color:#fb6d3b' : ''" style="border: 1px solid #fff;padding: 6px;border-radius: 5px" color="blue darken-1" text @click="editNewCredit()">
              <span :style="canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("edit_credit") }}</span>
            </v-btn>
            <v-btn :style="! canSeeStoredCredit ? 'background:#fff' : ''" class="ml-3" color="primary" style="color: #fff;border: 1px solid #fff;padding: 6px;border-radius: 5px" dark text @click="addNewCredit()">
              <span :style="! canSeeStoredCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("add_credit1") }}</span>
            </v-btn>
          </span>
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
<!--        <p v-if="canSeeStoredCredit" style="font-size: 17px" class="mb-5 mt-3">{{ $t('credit') }}</p>-->
        <v-select class="mt-8"
          v-if="canSeeStoredCredit"
          :label="$t('credit')"
          v-model="credit"
          :items="storedCredits"
          hide-details
          outlined
          v-on:change="selectCredit"
          dense
          item-value="id"
          item-text="name"
        ></v-select>
        <div v-if="canSeeInputFields">
          <v-text-field class="mt-6"
              v-model="modalCreditItems.naziv"
              :label="$t('add_title')"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-switch
                v-model="modalCreditItems.percent"
                inset
                :label="$t('percent')"
              ></v-switch>
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                v-model="modalCreditItems.rabat"
                :label="$t('add_rabat')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_from"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_from"
                      :label="$t('*inital_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_from"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(modalCreditItems.date_from)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  :return-value.sync="modalCreditItems.date_to"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="modalCreditItems.date_to"
                      :label="$t('ending_date')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="modalCreditItems.date_to"
                    no-title
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.menu1.save(modalCreditItems.date_to)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                  v-model="modalCreditItems.active"
                  inset
                  :label="$t('status')"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="openModalAddCredit = false">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeCredit()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="openModalSettings" max-width="60%" scrollable>
    <v-card>
      <v-card-title class="primary" style="width: 100%">
        <span style="font-size: 20px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
          {{ $t('settings')}} | {{ firm.id ? firm.name : editedItem.name }} | {{ 'ID: ' + firm.id ? firm.id : editedItem.id }}
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div style="margin-top: 30px">
          <v-switch
            v-model="firm.settings.confirm_delivery"
            inset
            :label="$t('automatic_delivery_no_confirm_company')"
          ></v-switch>
       </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
            v-model="firm.settings.can_see_report"
            inset
            :label="$t('can_see_report')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
              v-model="firm.settings.can_see_ordered"
              inset
              :label="$t('can_see_ordered')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
              v-model="firm.settings.all_cooperation"
              inset
              :label="$t('allow_meal_to_partner')"
          ></v-switch>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-checkbox
              v-model="firm.settings.show_price"
              @click="changeShowPrice()"
              :label="translate($t('view')) + ' ' + translate($t('price')) + ' ' +  translate($t('order_meal'))"
          ></v-checkbox>
        </div>
        <hr>
        <div style="margin-top: 30px">
          <v-switch
              v-model="firm.settings.order_from_one_shift_to_another"
              inset
              :label="$t('order_meal_from_one_shift_to_another')"
          ></v-switch>
        </div>
        <hr>
        <div v-if="! firm.settings.order_from_one_shift_to_another">
          <p style="font-weight: 500;font-size: 14px;margin-top: 20px">{{ $t("order_meal_from_desc")}}</p>
          <p style="font-weight: 700;font-size: 15px;margin-top: -15px">{{ $t('delivery_shift')}}</p>
          <div v-if="firm.shift_one">
              <v-row>
                  <v-col lg="2" sm="12">
                      <p style="padding-top: 15px" class="font-weight-normal">
                          {{ $t('shift_one') }}*
                          <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                  </v-col>
                  <v-col lg="10" sm="10">
                      <v-row>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_from_hour"
                                        :label="$t('starting_from_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_from_min"
                                        :label="$t('starting_from_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_to_hour"
                                        :label="$t('up_to_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_one.delivery_to_min"
                                        :label="$t('up_to_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </div>
          <div v-if="firm.shift_two">
              <v-row>
                  <v-col lg="2" sm="12">
                      <p style="padding-top: 15px" class="font-weight-normal">
                          {{ $t('shift_two') }}*
                          <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                  </v-col>
                  <v-col lg="10" sm="10">
                      <v-row>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_from_hour"
                                        :label="$t('starting_from_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_from_min"
                                        :label="$t('starting_from_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_from"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_to_hour"
                                        :label="$t('up_to_h')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                          <v-col lg="3" sm="3">
                              <v-select class="vreme"
                                        :items="delivery_to"
                                        item-value="val"
                                        v-model="firm.settings.shift_two.delivery_to_min"
                                        :label="$t('up_to_min')"
                                        outlined
                              >
                              </v-select>
                          </v-col>
                      </v-row>
                  </v-col>
              </v-row>
          </div>
          <div style="margin-bottom: 20px" v-if="firm.shift_three">
                <v-row>
                    <v-col lg="2" sm="12">
                        <p style="padding-top: 15px" class="font-weight-normal">
                            {{ $t('shift_three') }}*
                            <span v-if="firm.delivery_from_hour && firm.delivery_to_hour" style="padding-top: 22px;font-size: 10px" class="font-weight-bold">({{ firm.delivery_from_hour + ':' + firm.delivery_from_min }} - {{ firm.delivery_to_hour + ':' + firm.delivery_to_min }})</span></p>
                    </v-col>
                    <v-col lg="10" sm="10">
                        <v-row>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_from"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_from_hour"
                                          :label="$t('starting_from_h')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_to"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_from_min"
                                          :label="$t('starting_from_min')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_from"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_to_hour"
                                          :label="$t('up_to_h')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                            <v-col lg="3" sm="3">
                                <v-select class="vreme"
                                          :items="delivery_to"
                                          item-value="val"
                                          v-model="firm.settings.shift_three.delivery_to_min"
                                          :label="$t('up_to_min')"
                                          outlined
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>
        </div>
        <div style="margin-top: 20px">
          <v-switch
              v-model="firm.settings.worker"
              inset
              :label="$t('enable_meal_pickup_another_branch')"
          ></v-switch>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeModalSettings()">
          <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
        </v-btn>
        <v-btn color="primary" style="background: #fb6d3b;color: #fff" dark text @click="storeFirmSettings()">
          <span style="color: #fff">{{ $t("save") }}</span>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import axios from "../plugins/axios";

export default {
  data: () => ({
    dialog: false,
    firm: {
      settings: {
        confirm_delivery: false,
        can_see_ordered: false,
        order_from_one_shift_to_another: true,
        can_see_report: false,
        all_cooperation: false,
        no_time_limit: false,
        show_price: false,
        worker: false,
      }
    },
    search: "",
    on: undefined,
    attrs: undefined,
    credit: '',
    menu_type: [],
    dialogDelete: false,
    openModalAddCredit: false,
    openModalSettings: false,
    openModalEditCredit: false,
    canSeeStoredCredit: true,
    canSeeInputFields: false,
    menu: null,
    menu1: null,
    modalEditCreditItems: '',
    modalCreditItems: {
      naziv: '',
      rabat: '',
      percent: '',
      date_from: null,
      date_to: null,
      active: '',
      date: '',
    },
    storedCredits: [],
    headers: [
      {
        text: "Id",
        align: "end",
        value: "id",
        //width: '25px'
      },
      {
        text: "title",
        align: "start",
        //width: '15%',
        value: "name",
      },
      {
        text: "address",
        align: "start",
        value: "address",
        // width: '15%',
        sortable: false,
      },
      // {
      //   text: "email",
      //   align: "start",
      //   //width: '15%',
      //   value: "email",
      //   sortable: false,
      // },
      {
        text: "credit_only",
        align: "start",
        //width: '15%',
        value: "is_credit",
        sortable: false,
      },
      {
        text: "menu_type",
        align: "center",
        value: "type",
        //width: '15%',
        sortable: false,
      },
      {
        text: "actions",
        align: "center",
        value: "actions",
        //width: '15%',
        sortable: false,
      },
      {
        text: "",
        //width: '15%',
        value: "data-table-expand",
      },
    ],
    meniType: '',
    meniTypeOrg: '',
    desserts: [],
    editedIndex: -1,
    editedParentIndex: -1,
    editedItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      no_time_limit:0,
      shift_one: 1,
      shift_two: 0,
      shift_three: 0,
      contract: 1,
      lat: '',
      long: '',
      type: 'small'
    },
    defaultItem: {
      name: "",
      city: "",
      address: "",
      phone: "",
      email: "",
      lib: "",
      pib: "",
      description: "",
      state: 1,
      checkReport:0,
      no_time_limit:0,
      shift_one: 1,
      shift_two: 0,
      shift_three: 0,
      contract: 1,
      lat: '',
      long: '',
      type: 'small'
    },
    user: '',
    delivery_from: [
      {text: '00', val: '00'},
      {text: '01', val: '01'},
      {text: '02', val: '02'},
      {text: '03', val: '03'},
      {text: '04', val: '04'},
      {text: '05', val: '05'},
      {text: '06', val: '06'},
      {text: '07', val: '07'},
      {text: '08', val: '08'},
      {text: '09', val: '09'},
      {text: '10', val: '10'},
      {text: '11', val: '11'},
      {text: '12', val: '12'},
      {text: '13', val: '13'},
      {text: '14', val: '14'},
      {text: '15', val: '15'},
      {text: '16', val: '16'},
      {text: '17', val: '17'},
      {text: '18', val: '18'},
      {text: '19', val: '19'},
      {text: '20', val: '20'},
      {text: '21', val: '21'},
      {text: '22', val: '22'},
      {text: '23', val: '23'},
      {text: '24', val: '24'},
    ],
    delivery_to: [
      {text: '00', val: '00'},
      {text: '15', val: '15'},
      {text: '30', val: '30'},
      {text: '45', val: '45'},
    ],
  }),

  computed: {
    // meniType() {
    //   return [
    //     {text:this.$t('small'),val:'small'},
    //     {text:this.$t('large'),val:'large'},
    //     {text:this.$t('group'),val:'group'}
    //   ]
    // },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_company') : this.$t('change_company');
    },
    getRole() {
      let usr = ''
      this.user.restaurants.filter(restoran => {
        restoran.users.filter(user => {
            if (user.id == this.user.id) {
              usr = user
            }
        })
      })
      return usr
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    "editedItem.contract"(newParam, oldParam) {
      // if (newParam == 2) {
      //   this.meniType.push({
      //     name: this.$t("table"),
      //     ime: "table",
      //   })
      // } else {
      //   this.meniType.filter((item,index) => {
      //     if (item.ime && item.ime == 'table') {
      //       this.meniType.splice(index,1);
      //     }
      //   })
      // }
      // this.$forceUpdate()
    }
  },

  created() {
    this.initialize();
    this.user = this.$store.state.user;
  },

  methods: {
    changeShowPrice() {
      axios.post('/companies/change-show-price', this.firm).then(({data}) => {
        if(data) {
          this.openModalSettings = false
        }
      })
    },
    storeFirmSettings() {
      axios.post('/companies/store-settings', this.firm).then(({data}) => {
        if(data) {
          this.openModalSettings = false
        }
      })
    },
    closeModalSettings() {
      this.openModalSettings = false
    },
    openSettings(item) {
      this.openModalSettings = true
      if (item.settings == null) {
        console.log('uso1')
        item.settings = {
          shift_one: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_two: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_three: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          confirm_delivery: false,
          order_from_one_shift_to_another: true,
          can_see_report: false,
          all_cooperation: false
        }
      }
      if (typeof item.settings != 'string' && ! item.settings.hasOwnProperty('shift_one')) {
      console.log('uso')
        item.settings = {
          shift_one: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_two: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          shift_three: {
            delivery_from_hour: '',
            delivery_from_min: '',
            delivery_to_hour: '',
            delivery_to_min: '',
          },
          confirm_delivery: false,
          order_from_one_shift_to_another: true,
          can_see_report: false,
          all_cooperation: false
        }
      }

      if(typeof item.settings == 'string') {
        console.log('uso2')
        if(!JSON.parse(item.settings).order_from_one_shift_to_another) {
          JSON.parse(item.settings)['order_from_one_shift_to_another'] = true
        }
        item.settings = JSON.parse(item.settings)
      }

      this.firm = item
      this.$forceUpdate()
    },
    editNewCredit() {
      this.canSeeStoredCredit = true
      this.canSeeInputFields = false
      this.credit = ''
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    addNewCredit() {
      this.canSeeStoredCredit = false
      this.canSeeInputFields = true
      this.modalCreditItems.naziv = ''
      this.modalCreditItems.rabat = ''
      this.modalCreditItems.percent = ''
      this.modalCreditItems.date_from = ''
      this.modalCreditItems.date_to = ''
      this.modalCreditItems.active = ''
      this.modalCreditItems.date = ''
    },
    creditIsActive(credits) {
      axios.post('/companies/credit-is-active', credits).then(({data}) => {
        this.storedCredits.filter(item => {
          if (item.id == data.id) {
            item = data
          }
        })
      })
    },
    selectCredit() {
      this.storedCredits.filter(item => {
        if (item.id == this.credit) {
          this.modalCreditItems = item
          this.modalCreditItems['naziv'] = item.name
          this.modalCreditItems['id'] = item.id
        }
      })
      this.canSeeInputFields = true
    },
    storeCredit() {
      axios.post('/companies/save-credit', this.modalCreditItems).then(({data}) => {
        if(data) {
          this.openModalAddCredit = false
          this.modalCreditItems.naziv = ''
          this.modalCreditItems.rabat = ''
          this.modalCreditItems.percent = ''
          this.modalCreditItems.date_from = ''
          this.modalCreditItems.date_to = ''
          this.modalCreditItems.active = ''
          this.modalCreditItems.date = ''
          this.credit = ''

          // this.getCredit()
        }
      })
    },
    getCredit(id) {
      axios.post('/companies/get-credit', {'company_id': id}).then(({data}) => {
        this.storedCredits = data
      })
    },
    openCredit(item) {
      this.getCredit(item.id)
      this.openModalAddCredit = true
      this.canSeeStoredCredit = true
      this.modalCreditItems = item
    },
    setCredit(item, index) {
      axios.put('/companies/' + item.id + '/is-credit', item).then(({data}) => {
        console.log(index)
      })
    },
    initialize() {
      axios.get("/companies").then((res) => {
        this.desserts = res.data;
      });
      axios.get("menus/menu-types").then((res) => {
        this.meniType = res.data;
        this.meniTypeOrg = res.data;
      });
    },

    editItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }

      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      if (item.parent_id) {
        this.editedIndex = this.desserts.findIndex(
          (i) => i.id === item.parent_id
        );
        this.editedParentIndex = this.desserts[
          this.editedIndex
        ].children.findIndex((i) => (i.id = item.id));
      } else {
        this.editedIndex = this.desserts.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      axios.delete("/companies/" + this.editedItem.id).then((res) => {
        if (this.editedParentIndex > -1) {
          this.desserts[this.editedIndex].children.splice(
            this.editedParentIndex,
            1
          );
        } else {
          this.desserts.splice(this.editedIndex, 1);
        }
        this.closeDelete();
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editedParentIndex = -1;
      });
    },

    save() {
      this.editedItem.parent_id = this.editedItem.parent
        ? this.editedItem.parent.id
        : 0;
      if (this.editedIndex > -1 || this.editedParentIndex > -1) {
        let _editedIndex = this.editedIndex;
        let _editedParentIndex = this.editedParentIndex;
        let _editedItem = this.editedItem;

        axios
          .put("/companies/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            if (_editedParentIndex > -1) {
              Object.assign(
                this.desserts[_editedIndex].children[_editedParentIndex],
                _editedItem
              );
            } else {
              Object.assign(this.desserts[_editedIndex], _editedItem);
            }
          });
      } else {
        let _editedItem = this.editedItem;
        axios.post("/companies", this.editedItem).then((res) => {
          _editedItem.id = res.data.id;
          if (_editedItem.parent_id) {
            let i = this.desserts.findIndex(
              (i) => i.id === _editedItem.parent_id
            );
            this.desserts[i].children.push(_editedItem);
          } else {
            this.desserts.push(_editedItem);
          }
        });
      }
      this.close();
    },
    print(id, type = false) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      console.log(stylesHtml);
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}

                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },
  },
};
</script>
<style>
.vreme .v-text-field__details {
    display: none;
}
.okolo {
  margin: 0 !important;
}
@media(max-width: 767px) {
  .okolo {
    display: block;
  }
  .v-card__text b {
    font-size: 16px;
  }
  #stampaj {
    display: none;
  }
}
.polje textarea {
    line-height: 0.9rem !important;
}
</style>
<style scoped>

</style>
