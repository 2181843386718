<template>
  <div style="width: 95%;margin: 10px auto">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("users") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-toolbar-title>
              {{ translate(restaurantName) }}: {{ $route.params.id }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
<!--            <import-excel :dialog="dialogImport" @close="dialogImport = false"></import-excel>-->
<!--            <v-btn v-if="$store.state.user.type != 'dostava' && $store.state.user.type != 'admin_delivery'" outlined rounded small color="#fb6d3b" @click="dialogImport = true">Import Excel</v-btn>-->
            <v-spacer></v-spacer>
            <v-text-field
              class="pr-4 pb-2"
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn v-if="$route.name == 'restoran-id-korisnici'"
                   outlined style="border-radius: 9px"
                   color="orange darken-4"
                   small
                   class="mr-2"
                   @click="$router.push({ name: 'QRlist', params: { res: idProp } })"
            >
              <v-icon class="mr-2">mdi-checkerboard</v-icon
              >{{ $t("qr") }}
            </v-btn>
            <v-btn v-else style="border-radius: 9px"
              outlined
              color="orange darken-4"
              small
              class="mr-2"
              @click="$router.push({ name: 'QRlist', params: { idProp } })"
            >
              <v-icon class="mr-2">mdi-checkerboard</v-icon
              >{{ $t("qr") }}
            </v-btn>

            <v-dialog v-model="dialog" max-width="600px" scrollable>
              <template v-slot:activator="{ on, attrs }">
                <v-btn :title="$t('print')" style="border-radius: 9px" outlined color="orange darken-4" small class="ml-2" @click="print('print')">
                  <v-icon class="">mdi-printer</v-icon>
                  <!--              {{ $t("print") }}-->
                </v-btn>
                <v-btn title="Vise korisnika odjednom" style="border-radius: 9px" outlined color="orange darken-4" class="ml-2" small @click.prevent="addMultipleUsers">
                  <v-icon>mdi mdi-account-multiple-plus</v-icon>
                </v-btn>
                <v-btn outlined color="orange darken-4" :style="'border-radius: 8px;border: 1px solid ' + backGround + ';background: none;color: ' + backGround + ';box-shadow: none;text-transform: capitalize'" dark v-bind="attrs" v-on="on" small>
                  <v-icon class="mr-2">mdi mdi-account-plus</v-icon>{{ $t("add_worker") }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="primary" style="display: flex;justify-content: space-between;align-content: center">
                  <span class="headline white--text"
                    >{{ translate(formTitle) }} - {{ editedItem.id }}
                  </span>
                  <v-btn color="white" text @click="close">
                    <v-icon class="mr-2">mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text>

                  <v-container>
                    <v-row class="mt-3">
                      <v-switch
                        v-model="editedItem.state"
                        inset
                        :label="$t('active')"
                        :false-value="0"
                        :true-value="1"
                        dense
                      ></v-switch>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-if="$route.params.parent_id || editedItem.children && editedItem.children.length > 0"
                        v-model="editedItem.is_transferable"
                        inset
                        :label="$t('change_location')"
                        :false-value="0"
                        :true-value="1"
                        dense
                      ></v-switch>
                      <v-switch
                          v-if="editedItem.all_cooperation"
                          v-model="editedItem.can_change_restoran_company"
                          inset
                          :label="$t('can_change_restoran_company')"
                          :false-value="0"
                          :true-value="1"
                          dense
                      ></v-switch>
<!--                      <v-switch-->
<!--                          v-if="editedItem.company && editedItem.company.no_time_limit"-->
<!--                          v-model="editedItem.no_time_limit_user"-->
<!--                          inset-->
<!--                          :label="$t('no_time_limit')"-->
<!--                          :false-value="0"-->
<!--                          :true-value="1"-->
<!--                          dense-->
<!--                      ></v-switch>-->
                      <v-row style="margin-top: 40px">
                        <v-col v-if="editedItem.image_path" cols="5">
                          <v-img style="max-width: 140px; margin: 0 auto" :src="conf.apiUrlBase + '/gallery/' + editedItem.image_path"></v-img>
                        </v-col>
                        <v-col cols="5">
                          <input type="file" id="upload" @change="upload" />
                          <label for="upload">{{ $t("add_new_image") }}</label>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.name"
                            :rules="[$t('required_field')]"
                            :label="$t('name')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.lastname"
                            :rules="[$t('required_field')]"
                            :label="$t('last_name')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" :title="translate('Предлог ime.prezime@domen_firme')">
                          <v-text-field
                            v-model="editedItem.email"
                            :rules="[$t('required_field')]"
                            :label="$t('email_or_username')"
                          ></v-text-field>
                          <p style="font-size: 15px;color: #c20606;border: 1px solid darkred" v-if="errorMessageEmail">{{ errorMessageEmail }}</p>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.password"
                            :rules="[$t('required_field')]"
                            :label="$t('password')"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" v-if="$store.state.company&&$store.state.company.children.length>0&&formTitle=='Измјена корисника'">
                          <v-select
                            v-if="$store.state.company"
                            :items="$store.state.company.children"
                            :label="$t('office')"
                            item-text="name"
                            item-value="id"
                            :rules="[$t('required_field')]"
                            v-model="editedItem.company_id"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <v-select
                            :disabled="editedItem.type === 'admin'"
                            :items="
                              $route.name === 'restoran-id-korisnici'
                                ? [{text: $t('restaurant'),val:'restaurant'},
                                   {text:translate('QR скенер / подела наруџби'),val:'payment'},
                                   {text:translate('Извештаји / наруџбе радник'),val:'report'},
                                   {text:translate('Извештаји / наруџбе безуговорца'),val:'report_guest'},
                                   {text:$t('stock'),val:'warehouse'},
                                   {text:$t('table'),val:'table'},
                                   // {text:$t('delivery1'),val:'delivery'}
                                  ]
                                : $route.params.delivery && $route.params.delivery === 'dostava' ?
                                   [ {text: translate('Достављач'), val:'dostava'},
                                   { text: translate('Админ - Достава'), val:'admin_delivery'},
                                  ]
                                : [{text: $t('worker'), val:'customer'},
                                   {text:translate('Компанија - администратор'),   val:'company'},
                                   {text:translate('Пријем / Испорука оброка'), val:'recipient'},
                                   {text:translate('Гост'), val:'guest'},
                                    {text:$t('table'),val:'table'},
                                  ]
                            "
                            :label="$t('user_type')"
                            :item-text="item => translate(item.text)"
                            item-value="val"
                            :rules="[$t('required_field')]"
                            v-model="editedItem.type"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <template v-if="editedItem.type === 'recipient'">
                        <v-col cols="6">
                          <v-switch
                              v-model="editedItem.is_contract"
                              inset
                              :label="$t('is_contract')"
                              :false-value="0"
                              :true-value="1"
                              dense
                          ></v-switch>
                        </v-col>
                        <v-col cols="6">
                          <v-switch
                              v-model="editedItem.is_non_contract"
                              inset
                              :label="$t('is_non_contract')"
                              :false-value="0"
                              :true-value="1"
                              dense
                          ></v-switch>
                        </v-col>
                      </template>
                      <v-col cols="12">
                        <v-card flat v-if="editedItem.type == 'recipient'">
<!--                          <v-card-text>-->
                            <v-autocomplete
                                v-model="searchs"
                                :items="companies[0].children.filter((i) => i.name)"
                                dense
                                filled
                                multiple
                                :label="$t('choose_office')"
                                item-text="name"
                                item-value="id"
                                clear
                                chips
                                small-chips
                            >
                              <v-btn
                                  color="primary"
                                  slot="prepend-item"
                                  @click="searchs=[]"
                                  small
                                  class="mx-4"
                              >
                                {{$t("display_all")}}
                              </v-btn>
                            </v-autocomplete>
                            <div >
                              <template
                                v-for="(company) in searchs && searchs.length > 0
                                  ? companies[0].children.filter((i) => searchs.includes(i.name))
                                  : companies[0].children"
                                   dense
                              >
                              </template>
                            </div>
<!--                          </v-card-text>-->
                        </v-card>
                      </v-col>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.address"
                            :label="$t('address')"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                        <v-text-field
                          v-model="editedItem.phone"
                          :label="$t('phone')"
                        ></v-text-field>
                      </v-col>
                      </v-row>
                      <!-- <v-col cols="12">
                        <v-text-field
                          v-model="editedItem.code"
                          :label="translate('Екстерни код')"
                        ></v-text-field>
                      </v-col> -->
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.workPos"
                            :label="$t('work_place')"
                          ></v-text-field>
                        </v-col>
  <!--                      <v-col cols="12">-->
  <!--                        <v-select-->
  <!--                          v-model="editedItem.notification"-->
  <!--                          :items="items"-->
  <!--                          :label="$t('select_notification')"-->
  <!--                        ></v-select>-->
  <!--                      </v-col>-->
                        <v-col cols="6">
                        <v-text-field
                            v-model="editedItem.external_code"
                            :label="translate('Ekstrni ID')"
                        ></v-text-field>
                      </v-col>
                      </v-row>
                      <v-col cols="6" :title="translate('Датум регистрације')">
                          <v-icon class="mr-2">mdi-calendar</v-icon>
                          {{moment(editedItem.created_at).format("DD.MM.YYYY. h:mm:ss")}}
                      </v-col>
                      <v-col cols="6" :title="translate('Датум измене')">
                        <v-icon class="mr-2">mdi-calendar</v-icon>
                        {{moment(editedItem.updated_at).format("DD.MM.YYYY. h:mm:ss")}}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn color="primary" dark @click="save">
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">{{
                  $t("delete_confirmation")
                }}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">{{
                    $t("cancel")
                  }}</v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteItemConfirm"
                    >{{ $t("save") }}</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
    <div>
      <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="calories"
        class="elevation-1"
        dense
        id='print'
        :search="search"
      >
        <template
          v-for="header in headers"
          v-slot:[`header.${header.value}`]="{ header }"
        >
          {{ $t(header.text) }}
        </template>
        <template
          v-for="values in headers"
          v-slot:[`item.${values.value}`]="{ item }"
        >
          {{ translate(item[values.value]) }}
        </template>
        <template v-slot:item.type="{ item }">
          {{
            item.type == "customer" ? $t("worker") : item.type == "company" ? $t("firm") : item.type == "restaurant" ? $t("restaurant") : item.type == "recipient" ? $t("delivery") : item.type == "guest" ? $t("guest") : item.type == "report" ? $t("report") : item.type == "report_guest" ? $t("guest_report") : item.type == "warehouse" ? $t("stock") : item.type == "dostava" ? $t("delivery1") : item.type == "admin_delivery" ? $t("admin_delivery") : $t("delivery")
          }}
        </template>
        <template v-slot:item.state="{ item }">
<!--          {{ item.state ? translate("Активан") : translate("Неактиван") }}-->
          <span style="color: #1e7e34" v-if="item.state">{{ $t("active") }}
<!--            <v-icon small style="color: #1e7e34" class="px-2">mdi-check</v-icon>-->
          </span>
          <span style="color: #c20606" v-if="! item.state">{{ $t("inactive") }}
<!--            <v-icon small style="color: #c20606;font-size: 10px" class="px-2">mdi-circle</v-icon>-->
          </span>
        </template>
        <template v-if="$store.state.user.type == 'admin'" v-slot:item.restoran="{ item, index }">
          <v-checkbox :title="$t('add_sub_restaurant')" v-model="add_restoran[index]" @change="setAddRestoran(item, index)"></v-checkbox>
        </template>
        <template v-if="$store.state.user.type == 'admin'" v-slot:item.podesavanja="{ item, index }">
          <v-checkbox :title="$t('user_settings')" v-model="item.pivot.settings" @change="setPodesavanja(item, index)"></v-checkbox>
        </template>

        <template v-slot:item.can_report="{ item, index }">
          <span style="display: flex;justify-content: center">
          <v-checkbox v-if="item.type == 'company'" :title="$t('report')" v-model="item.can_report" @change="setCanReport(item, index)"></v-checkbox>
            </span>
        </template>

        <template v-slot:item.time_limit="{ item, index }" style="display: flex;justify-content: center">
          <span v-if="item.company && item.company.no_time_limit" style="display: flex;justify-content: center">
            <v-checkbox v-if="item.company.no_time_limit" :title="$t('report')" v-model="item.no_time_limit_user"  :false-value="0" :true-value="1" @change="setTimeLimit(item, index)"></v-checkbox>
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="close2(item)" class="px-2">mdi-qrcode-scan</v-icon>
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          <span v-if="item.pivot && idProp == item.pivot.company_id && item.company.is_credit /*&& item.type == 'customer'*/">
            <v-icon small @click="openCredit(item)" style="color: #1e7e34">mdi-currency-eur</v-icon>
          </span>
        </template>
        <template v-slot:no-data>
          <v-btn :style="'border-radius: 8px;border: 1px solid ' + backGround + ';background: none;color: ' + backGround + ';box-shadow: none;text-transform: capitalize'" @click="initialize">
            {{ $t("resets") }}
          </v-btn>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog2" max-width="500px" scrollable>
        <v-card>
          <v-card-title class="primary">
            <span class="headline white--text">{{ $t("qr_code") }}</span>
            <v-spacer></v-spacer>
            <span class="headline white--text"
              >{{ translate(editedItem.name) + " " +translate(editedItem.lastname) }}: {{ editedItem.id }}</span
            >
          </v-card-title>

          <v-card-text>
            <v-row justify="center" class="pt-5">
              <vue-qrcode
                :value="
                  JSON.stringify({
                    id: this.selectedQr,
                    secure: this.selectedQrSecret,
                  })
                "
                :options="{ width: 200 }"
                style="border: 2px solid gray"
              ></vue-qrcode>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close2">
              {{ $t("close") }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="openModalCredit" max-width="800px" scrollable>
        <v-card>
          <v-card-title class="primary" style="width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
              {{ $t("credit_only") }}
               <span>
                 <v-btn :style="! canSeeEditCredit ? '' : 'background:#fff; color:#fb6d3b'" style="border: 1px solid #fff;padding: 6px;border-radius: 5px" color="darken-1" text @click="editCredit()">
                  <span :style="! canSeeEditCredit ? 'color:#fff' : 'color: #fb6d3b'" style="color: #fff;font-size: 11px;">{{ $t("edit_credit") }}</span>
                </v-btn>
                <v-btn :style="canSeeAddCredit ? 'background:#fff' : ''" class="ml-3" color="primary" style="color: #fff;border: 1px solid #fff;padding: 6px;border-radius: 5px" dark text @click="addCredit()">
                  <span :style="canSeeAddCredit ? 'color:#fb6d3b' : 'color: #fff'" style="color: #fff;font-size: 11px;">{{ $t("add_credit1") }}</span>
                </v-btn>
               </span>
<!--              <span>{{ titleCase(translate(modalCreditItems.name)) + ' ' + titleCase(translate(modalCreditItems.lastname)) }} | ID: {{ modalCreditItems.id }}</span>-->
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div v-if="canSeeEditCredit">
              <p class="mb-0 mt-3 mb-1 font-weight-bold">{{ translate('Историја кредита') }}:</p>
              <p class="text-center" v-if="! modalCreditUser.credit.length">{{ translate('Корисник нема унесен кредит') }}</p>
              <v-data-table v-else
                  :headers="headersCredit"
                  :items="modalCreditUser.credit"
                  sort-by="calories"
                  class="elevation-1"
                  dense
                  id='print'
                  :search="search"
              >
                <template
                    v-for="header in headers"
                    v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ translate(header.text) }}
                </template>
                <template
                    v-for="values in headers"
                    v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template v-slot:item.credit_type="{ item }">
                  {{ getCreditName(item.credit_type_id) }}
                </template>
                <template v-slot:item.date_to="{ item }">
                  {{ formatDate(validTo(item.credit_type_id)) }}
                </template>
                <template v-slot:item.date="{ item }">
                  {{ formatDate(item.date) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="creditEdit(item)">mdi-pencil</v-icon>
                  <v-icon small @click="creditDelete(item)"> mdi-delete </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    {{ $t("resets") }}
                  </v-btn>
                </template>
              </v-data-table>
              <div v-if="editCredtiItem">
                <p class="mb-0 mt-3 mb-1 font-weight-bold">{{ $t('credit') }}:</p>
                <v-select class="mt-2"
                  :label="$t('credit_edit')"
                  v-model="creditItemEdit.credit_type_id"
                  :items="storedCredits"
                  hide-details
                  outlined
                  v-on:change="selectCredit"
                  dense
                  item-value="id"
                  item-text="name"
                ></v-select>
                <v-row>
                  <v-col>
                    <v-text-field class="mt-3"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  v-model="creditItemEdit.duguje"
                                  type="number"
                                  :label="translate('*Унесите Допунy...')"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field class="mt-3"
                                  type="number"
                                  oninput="if(this.value < 0) this.value = 0;"
                                  v-model="creditItemEdit.potrazuje"
                                  :label="translate('*Унесите Потрошњy...')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="openModalCredit = false">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn v-if="canSeeButtons" style="background: #fb6d3b;color: #fff" color="primary" dark text @click="storeUserCreditEdit()">
                  <span style="color: #fff">{{ $t("save") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
            <div v-if="! canSeeEditCredit">
              <v-select
                class="mt-5"
                :label="$t('choose_credit')"
                v-model="modalCreditItems.izabrani_kredit"
                :items="storedCredits"
                hide-details
                outlined
                v-on:change="selectCredit"
                dense
                item-value="id"
                item-text="name"
              ></v-select>
              <p style="color: #949494; font-size: 11px">
                {{ modalCreditItems.izabrani_kredit ? translate('Валидно од: ') + formatDate(storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).date_from) + ' - ' + translate('Валидно до: ') + formatDate(storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).date_to) + ' | ' + translate('Проценат: ') + storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).percent + ' | ' + translate('Рабат: ') + storedCredits.find(id => id.id == modalCreditItems.izabrani_kredit).rabat : '' }}
              </p>
              <v-row justify="center" class="pt-5">
                <v-text-field
                  class="px-4"
                  type="number"
                  v-model="modalCreditItems.kredit"
                  :label="translate('*Унесите кредит...')"
                ></v-text-field>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="openModalCredit = false">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn style="background: #fb6d3b;color: #fff" color="primary" dark text @click="storeUserCredit()">
                  <span style="color: #fff">{{ $t("add") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addMultiple" max-width="100%" scrollable>
        <v-card>
          <v-card-title class="primary" style="width: 100%">
            <span style="font-size: 18px !important; justify-content: space-between;width: 100%" class="headline white--text text-center d-flex">
              {{ $t("add_worker") }}
               <span>
                 <v-btn color="white darken-1" text @click="addMultiple = false">
                    X
                  </v-btn>
               </span>
            </span>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <div class="mt-4">
              <div v-for="(usr, index) in multipleUsers" style="display: flex;justify-content: space-between;align-items: center;height: 62px">
<!--                <v-switch-->
<!--                    v-model="usr.state"-->
<!--                    inset-->
<!--                    :label="$t('active')"-->
<!--                    :false-value="0"-->
<!--                    :true-value="1"-->
<!--                    dense-->
<!--                ></v-switch>-->
<!--                <v-switch-->
<!--                    v-if="$route.params.parent_id || usr.children && usr.children.length > 0"-->
<!--                    v-model="usr.is_transferable"-->
<!--                    inset-->
<!--                    :label="$t('change_location')"-->
<!--                    :false-value="0"-->
<!--                    :true-value="1"-->
<!--                    dense-->
<!--                ></v-switch>-->
<!--                <v-switch-->
<!--                    v-if="usr.all_cooperation"-->
<!--                    v-model="usr.can_change_restoran_company"-->
<!--                    inset-->
<!--                    :label="$t('can_change_restoran_company')"-->
<!--                    :false-value="0"-->
<!--                    :true-value="1"-->
<!--                    dense-->
<!--                ></v-switch>-->
<!--                <v-col cols="1" v-if="usr.image_path">-->
<!--                  <v-img style="max-width: 140px; margin: 0 auto" :src="conf.apiUrlBase + '/gallery/' + usr.image_path"></v-img>-->
<!--                </v-col>-->
<!--                <v-col cols="1">-->
<!--                  <input type="file" id="upload" @change="upload" />-->
<!--                  <label for="upload">{{ $t("add_new_image") }}</label>-->
<!--                </v-col>-->
                <v-col >
                  <v-text-field
                      v-model="usr.name"
                      :rules="[$t('required_field')]"
                      :label="$t('name')"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="usr.lastname"
                      :rules="[$t('required_field')]"
                      :label="$t('last_name')"
                  ></v-text-field>
                </v-col>
                <v-col :title="translate('Предлог ime.prezime@domen_firme')">
                  <v-text-field
                      v-model="usr.email"
                      :rules="[$t('required_field')]"
                      :label="$t('email_or_username')"
                  ></v-text-field>
                  <p style="font-size: 15px;color: #c20606;border: 1px solid darkred" v-if="errorMessageEmail">{{ errorMessageEmail }}</p>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="usr.password"
                      :rules="[$t('required_field')]"
                      :label="$t('password')"
                  ></v-text-field>
                </v-col>
                <v-col v-if="$store.state.company&&$store.state.company.children.length>0&&formTitle=='Измјена корисника'">
                  <v-select
                      v-if="$store.state.company"
                      :items="$store.state.company.children"
                      :label="$t('office')"
                      item-text="name"
                      item-value="id"
                      :rules="[$t('required_field')]"
                      v-model="usr.company_id"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                      :disabled="usr.type === 'admin'"
                      :items="
                        $route.name === 'restoran-id-korisnici'
                          ? [{text: $t('restaurant'),val:'restaurant'},
                             {text:translate('QR скенер / подела наруџби'),val:'payment'},
                             {text:translate('Извештаји / наруџбе радник'),val:'report'},
                             {text:translate('Извештаји / наруџбе безуговорца'),val:'report_guest'},
                             {text:$t('stock'),val:'warehouse'},
                             {text:$t('table'),val:'table'},
                             // {text:$t('delivery1'),val:'delivery'}
                            ]
                          : $route.params.delivery && $route.params.delivery === 'dostava' ?
                             [ {text: translate('Достављач'), val:'dostava'},
                             { text: translate('Админ - Достава'), val:'admin_delivery'},
                            ]
                          : [{text: $t('worker'), val:'customer'},
                             {text:translate('Компанија - администратор'),   val:'company'},
                             {text:translate('Пријем / Испорука оброка'), val:'recipient'},
                             {text:translate('Гост'), val:'guest'},
                              {text:$t('table'),val:'table'},
                            ]
                      "
                      :label="$t('user_type')"
                      :item-text="item => translate(item.text)"
                      item-value="val"
                      :rules="[$t('required_field')]"
                      v-model="usr.type"
                  ></v-select>
                </v-col>
                <template v-if="usr.type === 'recipient'">
                <v-col>
                  <v-switch
                      v-model="usr.is_contract"
                      inset
                      :label="$t('is_contract')"
                      :false-value="0"
                      :true-value="1"
                      dense
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                      v-model="usr.is_non_contract"
                      inset
                      :label="$t('is_non_contract')"
                      :false-value="0"
                      :true-value="1"
                      dense
                  ></v-switch>
                </v-col>
              </template>
                <v-card flat v-if="usr.type == 'recipient'">
                  <v-autocomplete
                      v-model="searchs"
                      :items="companies[0].children.filter((i) => i.name)"
                      dense
                      filled
                      multiple
                      :label="$t('choose_office')"
                      item-text="name"
                      item-value="id"
                      clear
                      chips
                      small-chips
                  >
                    <v-btn
                        color="primary"
                        slot="prepend-item"
                        @click="searchs=[]"
                        small
                        class="mx-4"
                    >
                      {{$t("display_all")}}
                    </v-btn>
                  </v-autocomplete>
                  <div >
                    <template
                        v-for="(company) in searchs && searchs.length > 0
                            ? companies[0].children.filter((i) => searchs.includes(i.name))
                            : companies[0].children"
                        dense
                    >
                    </template>
                  </div>
                  <!--                          </v-card-text>-->
                </v-card>
<!--                <v-col>-->
<!--                  <v-text-field-->
<!--                      v-model="usr.address"-->
<!--                      :label="$t('address')"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col>-->
<!--                  <v-text-field-->
<!--                      v-model="usr.phone"-->
<!--                      :label="$t('phone')"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
<!--                <v-col>-->
<!--                  <v-text-field-->
<!--                      v-model="usr.workPos"-->
<!--                      :label="$t('work_place')"-->
<!--                  ></v-text-field>-->
<!--                </v-col>-->
                <v-col>
                    <v-text-field
                        v-model="usr.external_code"
                        :label="translate('Ekstrni ID')"
                    ></v-text-field>
                  </v-col>
                <v-btn color="red darken-1" text @click.prevent="removeNewUser(index)">
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </div>
              <div class="text-right">
                <v-btn color="blue darken-1" text @click.prevent="addNewUser">
                  <span style="color: #fb6d3b">{{ $t("add") }}</span>
                </v-btn>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancelMultiple()">
                  <span style="color: #fb6d3b">{{ $t("cancel") }}</span>
                </v-btn>
                <v-btn style="background: #fb6d3b;color: #fff" color="primary" @click.prevent="multipleUserAdd()" dark text>
                  <span style="color: #fff">{{ $t("add") }}</span>
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
// import importExcel from '../components/import-excel'

export default {
  components: {
    VueQrcode,
    // importExcel
  },
  data: () => ({
    conf: conf,
    addMultiple: false,
    moment:moment,
    dialog: false,
    dialogImport: false,
    openModalCredit: false,
    canSeeEditCredit: false,
    canSeeButtons: false,
    editCredtiItem: false,
    creditItemEdit: '',
    canSeeAddCredit: true,
    modalCreditItems: '',
    modalCreditUser: '',
    multipleUsers: [{
      address:"",
      all_cooperation:"",
      can_change_restoran_company:0,
      children:"",
      code:"",
      company_delivery:Array[0],
      email:"",
      external_code:null,
      image_path:null,
      is_contract:1,
      is_non_contract:0,
      is_transferable:0,
      lastname:"",
      name:"",
      no_time_limit_user:0,
      notification:null,
      password:"",
      phone:"",
      state:1,
      workPos:"",
    }],
    search: "",
    searchs: [],
    idProp: null,
    kredit: '',
    add_restoran: [],
    podesavanja: [],
    dialogDelete: false,
    restaurantName: "",
    items: ["Email", "Telefon", "Ne zelim primati notifikacije"],
    headersCredit: [
      {
        text: "Тип кредита",
        align: "start",
        value: "credit_type",
      },
      {
        text: "Допуна",
        align: "start",
        value: "duguje",
      },
      {
        text: "Потрошња",
        align: "start",
        value: "potrazuje",
      },
      {
        text: "Датум до",
        align: "start",
        value: "date_to",
      },
      {
        text: "Датум",
        align: "start",
        value: "date",
      },
      {
        text: "Акције",
        align: "start",
        value: "actions",
      },
    ],
    headers: [
      {
        text: "Id",
        align: "start",
        value: "id",
      },
      {
        text: "name",
        align: "start",
        value: "name",
      },
      {
        text: "last_name",
        align: "start",
        value: "lastname",
      },
      {
        text: "email",
        align: "start",
        value: "email",
        sortable: false,
      },
      {
        text: "role",
        align: "start",
        value: "type",
        sortable: true,
      },
      // {
      //   text: "Екстерни код",
      //   align: "start",
      //   value: "code",
      //   sortable: false,
      // },
      {
        text: "status",
        align: "start",
        value: "state",
        sortable: true,
      },
      {
        text: "report",
        align: "start",
        value: "can_report",
        sortable: true,
      },
      {
        text: "time_limit",
        align: "start",
        value: "time_limit",
        sortable: true,
      },
      // {
      //   text: "+ Ресторан",
      //   align: "start",
      //   value: "restoran",
      //   sortable: true,
      // },
      // {
      //   text: "Подешавања",
      //   align: "start",
      //   value: "podesavanja",
      //   sortable: true,
      // },
      { text: "actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      all_cooperation: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      password: "",
      code: "",
      workPos: "",
      children: "",
      state: 1,
      is_contract: 1,
      is_non_contract: 0,
      // no_time_limit: 0,
      no_time_limit_user: 0,
      is_transferable: 0,
      can_change_restoran_company: 0,
      company_delivery: [],
      image_path: null,
      notification: null,
      external_code: null,
    },
    defaultItem: {
      name: "",
      all_cooperation: "",
      lastname: "",
      address: "",
      phone: "",
      email: "",
      password: "",
      code: "",
      workPos: "",
      children: "",
      state: 1,
      is_contract: 1,
      is_non_contract: 0,
      is_transferable: 0,
      // no_time_limit: 0,
      no_time_limit_user: 0,
      can_change_restoran_company: 0,
      image_path: null,
      notification: null,
      external_code: null,
    },
    selectedQr: false,
    selectedQrSecret: false,
    dialog2: false,
    errorMessageEmail: '',
    companies: '',
    storedCredits: '',
    credit: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('new_user') : this.$t('edit_user');
    },
    backGround() {
      if(this.$store.state.restaurant) {
        return this.$store.state.restaurant.settings
          ? this.$store.state.restaurant.settings.topbar.background
          : 'primary'
      }
      else if(this.$store.state.company &&  this.$store.state.company.settings &&  this.$store.state.company.settings.topbar) {
        return this.$store.state.company.settings
          ? this.$store.state.company.settings.topbar.background
          : 'primary'
      } else {
        return 'primary'
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    idProp() {
      if (this.$store.state.user.type != 'admin_delivery') {
        axios.post('/companies/get-active-credit', {'company_id': this.idProp}).then(({data}) => {
          data.filter(item => {
            item.credit_type_id = item.id
          })
          this.storedCredits = data
        })
      }
    }
  },

  created() {
    // this.getCredit()
    this.initialize();
    this.getCompanies();
    if(this.$store.state.user.type == 'admin') {
      this.headers.splice(6, 0, {
            text: "+ Ресторан",
            align: "start",
            value: "restoran",
            sortable: false,
          },
          {
            text: "Подешавања",
            align: "start",
            value: "podesavanja",
            sortable: false,
          });
    }
  },

  methods: {
    cancelMultiple() {
      this.addMultiple = false
      this.multipleUsers = []
      this.addNewUser()
      this.$forceUpdate()
    },
    multipleUserAdd() {
      axios.post('/companies/' + this.$route.params.id + '/add-multiple-users', this.multipleUsers).then(({data}) => {
        if (data) {
          this.multipleUsers.filter(item => {
            this.desserts.push(item);
          })
          this.addMultiple = false
          this.$forceUpdate()
        }
      })
    },
    removeNewUser(index) {
      this.multipleUsers.splice(index, 1);
    },
    addNewUser() {
      this.multipleUsers.unshift({
        address:"",
        all_cooperation:"",
        can_change_restoran_company:0,
        children:"",
        code:"",
        company_delivery:Array[0],
        email:"",
        external_code:null,
        image_path:null,
        is_contract:1,
        is_non_contract:0,
        is_transferable:0,
        lastname:"",
        name:"",
        no_time_limit_user:0,
        notification:null,
        password:"",
        phone:"",
        state: 1,
        workPos:"",
      })
    },
    addMultipleUsers() {
      this.addMultiple = true
    },
    setTimeLimit(item, index) {
      axios.post('/user/change-user-time-limit', item).then(({data}) => {
        console.log(data)
      })
    },
    validTo(credit) {
      let date = ''
      this.storedCredits.filter(item => {
        if (item.id == credit) {
          date = item.date_to
        }
      })
      return date
    },
    creditEdit(item) {
      this.canSeeButtons = true
      this.editCredtiItem = true
      this.creditItemEdit = item
    },
    creditDelete(item) {

    },
    getCreditName(credit) {
      let name = ''
      this.storedCredits.filter(item => {
        if (item.id == credit) {
          name = item.name
        }
      })
      return name
    },
    formatDate(date) {
      if (date) {
        return moment(date).format('DD.MM.YYYY')
      } else {
        return '/'
      }

    },
    editCredit() {
      this.canSeeEditCredit = true
      this.canSeeAddCredit = false
    },
    addCredit() {
      this.canSeeAddCredit = true
      this.canSeeEditCredit = false
      this.editCredtiItem = false
      this.canSeeButtons = false
    },
    storeUserCredit() {
      this.modalCreditItems['date'] = moment().format('DD.MM.YYYY')
      this.modalCreditItems['credit_type_id'] = this.modalCreditItems.credit_type_id
      this.modalCreditItems['duguje'] = this.modalCreditItems.kredit
      this.modalCreditItems['user_id'] = this.modalCreditUser.id
      axios.post('/user/add-user-credit', this.modalCreditItems).then(({data}) => {
        if(data) {
          this.openModalCredit = false
          this.modalCreditUser.credit.push(data)
          this.modalCreditItems.izabrani_kredit = ''
        }
      })
    },
    storeUserCreditEdit() {
      axios.post('/user/edit-user-credit', this.creditItemEdit).then(({data}) => {
        if(data) {
          this.openModalCredit = false
          this.modalCreditUser.credit.filter(item => {
            if(item.id == data.id) {
              item = data
            }
          })
          this.creditItemEdit = ''
          this.editCredtiItem = false
          this.canSeeButtons = false
        }
      })
    },
    selectCredit() {
      this.storedCredits.filter(item => {
        if (item.id == this.credit) {
          this.modalCreditItems = item
          this.modalCreditItems['naziv'] = item.name
          this.modalCreditItems['id'] = item.id
        }
      })
    },
    titleCase(str) {
      if (str) {
        let splitStr = str.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
      } else {
        return  ''
      }
    },
    openCredit(item) {
      this.openModalCredit = true
      this.modalCreditItems = item
      this.modalCreditItems.kredit = item.credit ? item.credit.duguje : ''
      this.modalCreditUser = item
      if (item.credit) {
        this.storedCredits.filter(item1 => {
          if (item1.id == item.credit.credit_type_id){
            this.credit = item.credit.credit_type_id
          }
        })
      } else {
        this.credit = ''
      }
    },
    setAddRestoran(item, index) {
      let data = {}
      data['restoran'] = this.add_restoran[index]
      data['user'] = item
      axios.post("/user/can-add-sub-restoran", data).then(({data}) => {
        console.log('OK')
      });
    },
    setCanReport(item, index) {
      let data = {}
      data['settings'] = this.podesavanja[index]
      data['user'] = item
      axios.post("/user/can-see-company-reports", data).then(({data}) => {
        console.log('OK')
        this.$forceUpdate()
      });
    },
    setPodesavanja(item, index) {
      let data = {}
      data['settings'] = this.podesavanja[index]
      data['user'] = item
      axios.post("/user/can-see-settings", data).then(({data}) => {
        console.log('OK')
        this.$forceUpdate()
      });
    },
    getCompanies() {
      if (this.$store.state.user.type != 'admin_delivery') {
        axios.get("/companies").then((res) => {
          this.companies = res.data;
        });
      }
    },
    initialize() {
      let route_name = null;
      let all_route = null
      this.idProp = this.$route.params.id;
      if (this.$route.name === "korisnici") {
        route_name = "companies";
        this.idProp = this.$route.params.id;
        this.$route.params.id = this.$store.state.company.id;
        this.$route.params.parent_id = this.$store.state.company.parent_id
        this.$route.params.name = this.$store.state.company.name;
      }
      if (this.$route.name === "firma-id-korisnici") route_name = "companies";
      if (this.$route.name === "restoran-id-korisnici")
        route_name = "restaurants";
      console.log(this.$route.params.delivery)
      if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
        all_route = "/" + route_name + "/" + this.$route.params.id + "/users-delivery"
      } else {
        all_route = "/" + route_name + "/" + this.$route.params.id + "/users"
      }

      axios
        .get(all_route)
        .then((res) => {
          this.restaurantName = res.data.name;
          this.desserts = res.data.users;
          this.desserts.filter((des,index) => {
            this.add_restoran[index] = des.pivot.add_restoran;
            this.podesavanja[index] = des.pivot.settings;
          })
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.company_id = this.$route.params.id;

      this.editedItem.all_cooperation = this.editedItem.company && this.editedItem.company.settings ? this.editedItem.company.settings.all_cooperation : ''
      if (this.companies) {
        this.companies.filter(items => {
          if (items.id == item.company ? parseInt(item.company.id) : parseInt(item.company_id)) {
            this.editedItem.children = items.children
          }
        })
      }

      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let _editedIndex = this.editedIndex;
      axios.delete("/user/" + this.editedItem.id).then((res) => {
        this.desserts.splice(_editedIndex, 1);
        this.closeDelete();
      });
    },

    close2(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      axios.get(`/user/${this.editedItem.id}/hash`).then((res) => {
        this.selectedQrSecret = res.data;
        this.selectedQr = this.editedItem.id;
        this.dialog2 = !this.dialog2;
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    upload() {
      let data = new FormData();
      data.append("image", event.target.files[0]);
      data.append("image_path", "users/photos/");

      axios.post("/user/photo", data).then((res) => {
        this.editedItem.image_path = "users/photos/small/" + res.data;
      });
    },
    save() {
      this.errorMessageEmail = ''
      let canClose = true;
      let all_route = null;
      if (! this.editedItem.hasOwnProperty('type')) {
        this.errorMessageEmail = this.translate('Поље тип не сме бити празно')
        canClose = false
        this.dialog = true;
        return
      }
      if (this.editedIndex > -1) {
        let _editedItem = this.editedItem;
        let _editedIndex = this.editedIndex;

        axios.patch("/user/" + this.editedItem.id, this.editedItem)
          .then((res) => {
            Object.assign(this.desserts[_editedIndex], _editedItem);
          });
      } else {
        let _editedItem = this.editedItem;
        //axios.post('/restaurants/' + this.$route.params.id + '/users', this.editedItem, {headers: {restaurant: this.$route.params.id}}).then(res => {
        let route_name = null;
        if (this.$route.name === "firma-id-korisnici" || this.$route.name === "korisnici")
          route_name = "companies";
        if (this.$route.name === "restoran-id-korisnici")
          route_name = "restaurants";

        if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
          all_route = "/" + route_name + "/" + this.$route.params.id + "/users-delivery"
          _editedItem.can_report = 0;
        } else {
          all_route = "/" + route_name + "/" + this.$route.params.id + "/users"
        }
        // axios.post("/" + route_name + "/" + this.$route.params.id + "/users", this.editedItem)
        axios.post(all_route, this.editedItem)
            .then((res) => {
            _editedItem.id = res.data.id;
            if (this.$route.params.delivery && this.$route.params.delivery == 'dostava') {
              this.desserts = res.data
            } else {
              this.desserts.push(_editedItem);
            }
          }).catch((error) => {
            console.log(error.response.data)
          if (error.response.data.status == "failed") {
            this.errorMessageEmail = error.response.data.message
            canClose = false
            this.dialog = true;
            return
          }
          });
      }
      if (canClose){
        this.close();
      }

    },
    print(id) {
      var allRows = document.getElementById(id).querySelector('table').rows;
      for (var i=0; i< allRows.length; i++) {
        allRows[i].lastElementChild.style.display = "none";
      }

      const prtHtml = document.getElementById(id).innerHTML;

      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // console.log(stylesHtml);
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                         <style>
                                       table {
                                        font-family: arial, sans-serif;
                                        border-collapse: collapse;
                                        width: 100%;
                                      }

                                      td, th {
                                        border: 1px solid #dddddd;
                                        text-align: left;
                                        padding: 8px;
                                      }

                                      tr:nth-child(even) {
                                        background-color: #f1f1f1;
                                      }
                                      </style>
                                      </head>
                                      <body>
                                        ${prtHtml}
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
      var allRows = document.getElementById(id).querySelector('table').rows;
      for (var i=0; i< allRows.length; i++) {
        allRows[i].lastElementChild.style.display = "block";
      }

    },
  },
};
</script>
